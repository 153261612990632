import { SET_BANNERS, SET_HOME_CATEGORIES, SET_HOME_MERCHANTS, SET_HOME_POPULAR_CATEGORIES, SET_HOME_PREMIUM_PARTNERS } from "./home_types";

const initialState = {
    premiumPartners:[] as any[],
    banners:[] as any[],
    categories:[] as any[],
    popularCategories:[] as any[],
    merchants:[] as any[],
}

export const homeReducer =(state=initialState,action:any) =>{
    switch (action.type) {
        case SET_BANNERS:
            return {...state,banners:action.banners}
        case SET_HOME_CATEGORIES:
            return {...state,categories:action.categories}
        case SET_HOME_MERCHANTS:
            return {...state,merchants:action.merchants}
        case SET_HOME_POPULAR_CATEGORIES:
            return {...state,popularCategories:action.popularCategories}
        case SET_HOME_PREMIUM_PARTNERS:
            let newState =  {...state,premiumPartners:action.premiumParnters}
            return newState;
         default:
            return state;
    }
}
