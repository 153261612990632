import React from "react";
import { primaryColor } from "../../_theme/colors";
import EmptyBox from "../../_shared/components/empty_box";
export default function PrimaryButton({
  title,
  minWidth,
  onClick,
  maxHeight,
  color,
  imageSource
}: any) {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: color || primaryColor,
        display: "flex",
        justifyContent: "space-between",
        paddingInline: "40px",
        paddingBlock: "8px",
        borderRadius: "8px",
        minWidth,
        textAlign: "left",
        cursor: "pointer",
        maxHeight: maxHeight,
      }}
    >  
    <h1 className="baloo2-semi-bold-white-24px-2">{title}</h1>
       <EmptyBox size={5} />
      <Image src={imageSource} />
    </div>
  );
}
const Image = ({ src }: any) => (
  <div
    style={{
      //width: "16vw",
      justifyContent: "center",
      alignItems: "left",
      borderRadius: "10px",
      display: "inline-block",
      position: "relative",
    }}
  >
    <img src={src} alt="" style={{ width: "100%", height: "100%"}} />
  </div>
);
