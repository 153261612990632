import React from "react";
import { LeftArrow, RightArrow } from "../../../../../_assets/images";
import EmptyBox from "../../../../../_shared/components/empty_box";
import "./categories_list.css";
import CategoriesListItem from "./categories_list_item";

export default function CategoriesList({ list }: any) {
  return (
    <div style={listStyle}>
      <EmptyBox size="56px" right="true" className="hide-on-small-screen" />
      <img
        src={LeftArrow}
        alt="left_arrow"
        className="hide-on-small-screen"
        style={{ height: 24, paddingTop: "40px" }}
        onClick={slideLeft}
      />
      <EmptyBox size="32px" right="true" className="hide-on-small-screen" />

      <div
        id="categories_list"
        style={premiumListStyle as any}
       // className="hideScrollBar"
      >
        {(list as []).map((e, index) => (
          
          <CategoriesListItem
            key={index}
            item={e}
            index={index}
          ></CategoriesListItem>
        ))}
      </div>
      <EmptyBox size="32px" className="hide-on-small-screen" right="left" />
      <img
        src={RightArrow}
        alt="right_arrow"
        style={{ height: 24, paddingTop: "40px" }}
        className="hide-on-small-screen"
        onClick={slideRight}
      />
      <EmptyBox size="56px" right="true" className="hide-on-small-screen"
       />
    </div>
  );
}

function slideLeft() {
  const list = document.getElementById("categories_list");
  list!.scrollLeft = list!.scrollLeft - 100;
}

function slideRight() {
  const list = document.getElementById("categories_list");
  list!.scrollLeft = list!.scrollLeft + 100;
}

const listStyle = {
  display: "flex",
  minHeight: "140px",
  alignItems: "start",
};

const premiumListStyle = {
  flexGrow: 1,
  display: "flex",
  alignItems: "start",
  overflowX: "auto",
  width: "100%",
};
