import store from "../../store";
import { setFavorites } from "../merchant-actions"
import { merchantApi } from "../merchant-api"

export const getFavourites =  async (dispatch:any) => {
  //const token = store.store.getState().authReducer.token;
  const res = await merchantApi.getFavorite()
  console.log("getFavourites",res)
  dispatch(setFavorites(res.data))
}

export const addFavourites1 =  async ({i,dispatch}:any) => {
  
  console.log("addFavourites starts",i)
  //const token = store.store.getState().authReducer.token;
  const res = await merchantApi.addFavourites({ 
    "offerId": "6622779c2110ea680b423dff"
})
  console.log("addFavourites",res)
  //dispatch(setFavorites(res.data))
}

export const addFavourites = async ({i,dispatch}:any) => {
  try {
    console.log("addFavourites starts22", i);
    //const token = store.store.getState().authReducer.token;
    const res = await merchantApi.addFavourites({ 
      "offerId": "6622779c2110ea680b423dff"
    });
    console.log("addFavourites", res);
    //dispatch(setFavorites(res.data));
  } catch (error) {
    console.error("Error occurred in addFavourites:", error);
    // Handle the error gracefully, such as showing an error message to the user
  }
};
