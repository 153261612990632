import { setIsLoginError, setToken, setUser, setUserId } from "../auth-actions"
import authApi from "../auth-api"

export const login =  async (dispatch:any,body:any) => {
    const res = await authApi.login(body)
    console.log("login auth thunk ********:",res) 
    if(res.data) {
      localStorage.setItem("token", JSON.stringify(res.data));
      dispatch(setToken(res.data.accessToken))
     // dispatch(setIsLoginError(true)) 
     console.log(5);
     var userData = await getUserData(res.data.accessToken)
     if(userData){
         console.log(6);
      dispatch(setUser(userData))
     }else{
         console.log(7);
         dispatch( setToken(null))
         dispatch( setUserId(null))
     }
    } else {

      dispatch(setIsLoginError(true)) 
     // dispatch(setUserId(res.data.result.id))
    //   await AsyncStorage.setItem('userId', res.data.result.id.toString())
    //   await AsyncStorage.setItem('family_head_id', `${res.data.result.family_head_id}`)
    //   await AsyncStorage.setItem('tracking_partner_id', `${res.data.result.tracking_partner_id}`)
    //   await AsyncStorage.setItem('paused_notification', `${res.data.result.paused_notification}`)
    //   await AsyncStorage.setItem('token', res.data.result.token)
    console.log(5);
    var userData = await getUserData(res.data.result.token)
    if(userData){
        console.log(6);
     dispatch(setUser(userData))
    }else{
        console.log(7);
        dispatch( setToken(null))
        dispatch( setUserId(null))
    }
    }
  }
  


  export const getUserData =  async (token:any) => {
    const res = await authApi.getUserData(
      //{params: {token}}
    )
    console.log("1111 login res:",res)
    if(res.data) {
        return{
            ...res.data,
          //  photo: `${res.data.result.profile.photo}?time=${new Date()}`,
            // paused_notification: JSON.parse(paused_notification)
          }; 
    }
  }

  export const updateUserData =  async (dispatch:any,body:any) => {
    try {
    console.log("updateUserData auth thunk ********:",body) 
    const res = await authApi.updateUserData(body)
    console.log("updateUserData auth thunk ********:",res) 
    if(res.data) {
      alert(res.data.message)
     var userData = await getUserData(res.data.accessToken);
     if(userData){
         console.log(6);
      dispatch(setUser(userData))
     }
    } }
    catch (error:any) {
      // Handle the bad request error here
      console.log("An error occurred updateUserData:", error.response.data.message);
      alert(error.response.data.message)
      // For example, you can dispatch an action to update the state with the error message
      // dispatch(setError(error.message));
  }
  }


  export const logout =  async (dispatch:any) => {
   
    dispatch( setToken(null))
    dispatch( setUserId(null))
  }