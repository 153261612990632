
export const SET_MERCHANTS = 'SET_MERCHANTS'
export const SET_PREMIUM_MERCHANTS = 'SET_PREMIUM_MERCHANTS'
export const SET_OFFERS = 'SET_OFFERS'
export const SET_CARD = 'SET_CARD'
export const SET_FAVORITES = 'SET_FAVORITES'
export const SET_MERCHANT_OFFERS = 'SET_MERCHANT_OFFERS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_MERCHANT_DETAILS = 'SET_MERCHANT_DETAILS'
export const SET_MERCHANT_PRODUCTS = 'SET_MERCHANT_PRODUCTS'
export const SET_FAVORITE = 'SET_FAVORITE'
export const SET_PREMIUM_BANNERS = 'SET_PREVIEW_MERCHANTS'
export const SET_LOCAL_CLIENTS = 'SET_LOCAL_CLIENTS'
export const SET_INTERNATIONAL_CLIENTS = 'SET_INTERNATIONAL_CLIENTS'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_ORGANIZATIONS_DETAILS = 'SET_ORGANIZATIONS_DETAILS'
export const SET_ADVERT = 'SET_ADVERT'
export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO'
export const SET_SOCIAL_MEDIA = 'SET_SOCIAL_MEDIA'
export const SET_HOTEL_SUB_CATEGORIES = 'SET_HOTEL_SUB_CATEGORIES'