import {
  SET_ADVERT,
  SET_CATEGORIES,SET_HOTEL_SUB_CATEGORIES, SET_FAVORITES,SET_CURRENT_VIDEO, SET_FAVORITE, SET_INTERNATIONAL_CLIENTS, SET_LOCAL_CLIENTS,
  SET_MERCHANT_DETAILS,
  SET_MERCHANT_OFFERS, SET_MERCHANT_PRODUCTS,
  SET_MERCHANTS,
  SET_OFFERS, SET_CARD, SET_ORGANIZATIONS, SET_PREMIUM_BANNERS,
  SET_PREMIUM_MERCHANTS, SET_SOCIAL_MEDIA
} from "./merchant-types";

export const setMerchants = (merchants) => ({type: SET_MERCHANTS, merchants})
export const setPremiumMerchants = (premiumMerchants) => ({type: SET_PREMIUM_MERCHANTS, premiumMerchants})
export const setOffers = (offers) => ({type: SET_OFFERS, offers})
export const setCard = (card) => ({type: SET_CARD, card})
export const setFavorites = (i) => ({type: SET_FAVORITES, i})
export const setMerchantOffers = (merchantOffers) => ({type: SET_MERCHANT_OFFERS, merchantOffers})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const setHotelSubCategories = (hotelSubCategories) => ({type: SET_HOTEL_SUB_CATEGORIES, hotelSubCategories})
export const setMerchantDetails = (merchantDetails) => ({type: SET_MERCHANT_DETAILS, merchantDetails})
export const setMerchantProducts = (merchantProducts) => ({type: SET_MERCHANT_PRODUCTS, merchantProducts})
export const setFavorite = (favorite) => ({type: SET_FAVORITE, favorite})
export const setPremiumBanners = (premiumBanners) => ({type: SET_PREMIUM_BANNERS, premiumBanners})
export const setLocalClients = (localClients) => ({type: SET_LOCAL_CLIENTS, localClients})
export const setInternationalClients = (internationalClients) => ({type: SET_INTERNATIONAL_CLIENTS, internationalClients})
export const setOrganizations = (organizations) => ({type: SET_ORGANIZATIONS, organizations})
export const setOrganizationDetails = (organizationDetails) => ({type: SET_ORGANIZATIONS, organizationDetails})
export const setAdvert = (advert) => ({type: SET_ADVERT, advert})
export const setCurrentVideo = (currentVideo) => ({type: SET_CURRENT_VIDEO, currentVideo})
export const setSocialMedia = (socialMedia) => ({type: SET_SOCIAL_MEDIA, socialMedia})
