import {
  SET_IS_LOGIN_ERROR,
  SET_PUBLIC_ORGANIZATIONS,
  SET_TOKEN,
  SET_USER,
  SET_USER_ID,
  SET_WORK_STATUS
} from "./auth-types";

const initialState = {
  user: null,
  isLoginError: false,
  token: '',
  // userId: null,
  // workStatus: null,
  // publicOrganizations: []
}

export function getToken(){
  return initialState.token
}

export const authReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_USER: return {...state, user: action.user}
    case SET_IS_LOGIN_ERROR: return {...state, isLoginError: action.isLoginError}
    case SET_TOKEN: return {...state, token: action.token}
    case SET_USER_ID: return {...state, userId: action.userId}
    case SET_WORK_STATUS: return {...state, workStatus: action.workStatus}
    case SET_PUBLIC_ORGANIZATIONS: return {...state, publicOrganizations: action.publicOrganizations}
    default: return state
  }
}