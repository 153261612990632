import React from "react";

export default function EmptyBox(props: any) {
  return <div style={getStyle(props)} className={props.className}></div>;
}

function getStyle({ size, left, right, inline, top, ...others }: any) {
  if (!left && !right && !inline && !top) return { margin: size, ...others };
  if (inline) return { marginInline: size, ...others };
  if (left) return { marginLeft: size, ...others };
  if (right) return { marginRight: size, ...others };
  if (top) return { marginTop: size, ...others };
}
