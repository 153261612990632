
import axios from 'axios'
import { getToken } from "../../auth/auth-reducer"
import store from "../../store"
import { setMerchantDetails, setMerchants, setPremiumMerchants ,setMerchantProducts} from "../merchant-actions"
import { merchantApi } from "../merchant-api"

export const getPremiumMerchants = async (dispatch:any) => {
      const token = store.store.getState().authReducer.token;
     const res = await merchantApi.getPremiumMerchant({
        params: {
            token,
            domain: `[['go_entity', '=', 'merchant'] , ['merchant_type', '=', 'premium']]`,
            fields: "['id','name','image_url', 'partner_latitude', 'partner_longitude', 'x_online_store','merchant_logo']",
            }
      })
      dispatch(setPremiumMerchants(res.data.result))
}

export const getMerchants = async (body:any,dispatch:any) => {
  console.log("getMerchants body:",body)
      const token = store.store.getState().authReducer.token;
      const res = await merchantApi.getMerchant(
            body
       )
     // console.log('data'+res.data);
       dispatch(setMerchants(res.data))
 }
 export const getMerchantsCateg = async (categoryId:any,dispatch:any) => {
  
      console.log('getMerchantsCateg getMerchantsCateg body',categoryId);
      const token = store.store.getState().authReducer.token;
      const res = await merchantApi.getMerchantCateg(
            categoryId
       )
     console.log('data 3333'+res.data);
       dispatch(setMerchants(res.data))
 }
   
 
export const getMerchantDetails = async (merchantId:any,dispatch:any) => {
      // console.log(1);
      // const token = store.store.getState().authReducer.token;
      // console.log(2);
      const res = await merchantApi.getMerchantDetails({
            merchantid:merchantId
       })
       //console.log(3);
       dispatch(setMerchantDetails(res.data))
 }

 ///  get produtcs 

 export const getMerchantProducts = async (merchantId:any,dispatch:any) => {
     
      const res = await merchantApi.getMerchantProducts({
            merchantId:merchantId
       })
       //console.log(3);
       dispatch(setMerchantProducts(res.data))
 }

 export const enquiryMailer = async (body: any, dispatch: any) => {
   console.log("Book now enquiryMailer *************:", body);
   try {
    const a =  {"params":body}
     const res = await axios.post(
       "https://golalitatwffer.com/api/go/sendinblue_emailWeb",
        a 
     );
     console.log("enquiryMailer res:", res);
     if(res.data.result && res.data.result.success){
      alert(res.data.result.success)
     }
     else{
      const a = JSON.parse(res.data.Error.response.text)
      console.log("enquiryMailer else res:",res.data.Error,"&&&&&&&",JSON.parse(res.data.Error.response.text))
      alert(a.code)
     }
   } catch (e) {
     console.log(e, "error enquiryMailer");
   }
 };
 
   