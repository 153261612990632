import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap({mapmerchGeoLat, mapmerchGeoLng}){
  const [directions, setDirections] = useState(null);
  const [destination, setDestination] = useState({ lat: 0, lng: 0 });

  const defaultProps = {
    center: {
      lat: mapmerchGeoLat,
      lng: mapmerchGeoLng
    },
    zoom: 11
  };

  const handleMapClick = ({ lat, lng }) => {
    setDestination({ lat, lng });
    calculateDirections(lat, lng);
  };

  const calculateDirections = (destLat, destLng) => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: new window.google.maps.LatLng(defaultProps.center.lat, defaultProps.center.lng),
        destination: new window.google.maps.LatLng(destLat, destLng),
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error("Error fetching directions:", status);
        }
      }
    );
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBdEnZO9X0H-d25fc0o4pr3m_PuvuC1eVI" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={handleMapClick}
      >
        <AnyReactComponent
          lat={destination.lat}
          lng={destination.lng}
          text="Destination"
        />
      </GoogleMapReact>

      {directions && (
        <div>
          <h2>Directions:</h2>
          <ol>
            {directions.routes[0].legs[0].steps.map((step, index) => (
              <li key={index}>{step.instructions}</li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
