import React,{useState} from "react";
import { connect } from "react-redux";
import { StarFavotire as StarFavotireImage,Redhearticon,HeartIcon,shareIcon,ShowIcon,
Eyegrey,Eyewhite,Heartgrey,Heartwhite

 } from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import { appUrl } from "../../../../_shared/constants/app_urls";
import { merchantApi } from "../../../../redux/merchant/merchant-api"

import { addFavourites } from "../../../../redux/merchant/thunks/wish_thunks";

import AllSvg from "../../assets/categ_all.svg";

function OfferGridItem({
  offer,
  addFavourites,
  globalySetCurrentBrowsingProduct,
}: any) {

  const [isSaved, setIsSaved] = useState(false);

     // Toggle clicked state

  const viewHandler = (j:any) => {
    console.log("viewHandler jjjj:",j)
    window.location.href =  `${appUrl}/offer/${j}`;
  //  handleCloseNavMenu();
  };

   const addFavouritesHandler = async (i:any) => {
    try {
      console.log("addFavourites starts addFavouritesHandler", i);
      //const token = store.store.getState().authReducer.token;
      const res = await merchantApi.addFavourites({ 
        "offerId": i
      });
      console.log("addFavourites", res);
      //dispatch(setFavorites(res.data));
    } catch (error) {
      console.error("Error occurred in addFavourites:", error);
      // Handle the error gracefully, such as showing an error message to the user
    }
  };

  const removeFavouritesHandler = async (i:any) => {
    try {
      console.log("addFavourites starts addFavouritesHandler", i);
      //const token = store.store.getState().authReducer.token;
      const res = await merchantApi.removeFavourites({ 
        "offerId": i
      });
      console.log("addFavourites", res);
      //dispatch(setFavorites(res.data));
    } catch (error) {
      console.error("Error occurred in addFavourites:", error);
      // Handle the error gracefully, such as showing an error message to the user
    }
  };
  const saveHandler = (i: any) => {
    
    console.log("saveHandler",i)
    if(isSaved){
      removeFavouritesHandler(i)
    }
    else{
      addFavouritesHandler(i);
    }
    setIsSaved(!isSaved);
  };
  
  return (
    <div style={cardStyle}>
      <div
        className="products-grid-card-with-shadow"
        style={{ paddingInline: "6px"//,background:'green'

         }}
      >
        <div style={titleStyle}>
          {/*<img alt="" src={product.merchLogo} style={imageStyle} />*/}
          {/* <EmptyBox size={8} inline="true" />
            <h1
              className="baloo2-semi-bold-black-18px"
              style={oneLineText as any}
            >
              {product.merchName}
            </h1>*/}
        </div>
        <EmptyBox size={8} />

        <img
          src={offer.offerImage}
          className="products-grid-card-image"
          alt=""
        />
        <EmptyBox size={8} />
        {/* <h1
            className="baloo2-normal-manatee-18px"
            style={{
              color: index % 3 !== 0 ? "transparent" : "",
              lineHeight: "2px",
            }}
          >
            {index % 3 === 0 ? "5 QAR" : "---"}
          </h1> 
          <h1
            className="baloo2-semi-bold-sky-blue-18px"
            style={{
              color: offer.productPrice[0].price != 0 ? undefined : "transparent",
            }}
          >
            {offer.productPrice[0].price != 0
              ? offer.productPrice[0].price + " QAR"
          : "UnApeard text"}
          </h1>*/}
        <EmptyBox size={4} />
        <hr style={{ borderTop: "0.5px solid #c3c3c3" }} />
        <EmptyBox size={4} />
        <h1 className="baloo2-semi-bold-black-18px" style={oneLineText as any}>
          {offer.offerName}
        </h1>
        <h1
          className="baloo2-normal-manatee-14px"
          style={textStyleElippesWithTowLines as any}
        >
          {offer.offerDescription}
        </h1>
        <EmptyBox size={12} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={isSaved ? selectedSavebottonWithBorderStyle : bottonWithBorderStyle} onClick={() => saveHandler(offer._id)}>
            <img
              src={isSaved?Heartwhite :Heartgrey}
              alt=""
              style={{ width: "15px", height: "15px"  }}
            />
            <EmptyBox size={2} />
            {isSaved ?<h1 className="baloo2-normal-white-12px">Save</h1>:<h1 className="baloo2-normal-manatee-12px">Save</h1>}
          </div>
          {/* <ShareButton /> */}
          <div
            style={bottonWithBorderStyle}
            onClick={() => viewHandler(offer._id)}
          >
            <img
              src={Eyegrey}
              alt=""
              style={{ width: "12px", height: "12px" }}
            />
            <EmptyBox size={2} />
            <h1 className="baloo2-normal-manatee-12px">View</h1>
          </div>
          {/* <ViewButton id={offer._id} /> */}
        </div>
        <EmptyBox size={12} />
      </div>
    </div>
  );
}

const cardStyle = {
  display: "flex",
  justifyContent: "center",
  //padding: "8px",
  //background:'red',
  padding: "8px 2px 8px 2px",
  cursor: "pointer",
};

const textStyleElippesWithTowLines = {
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
};

const SaveButton = () => (
  <div style={bottonWithBorderStyle}
      //onClick={()=>saveHandler()}
      >
    <img
      src={Redhearticon}
      alt=""
      style={{ width: "12px", height: "12px", color: "red" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">Save</h1>
  </div>
);

const ShareButton = () => (
  <div style={bottonWithBorderStyle}>
    <img
      src={shareIcon}
      alt=""
      style={{ width: "12px", height: "12px" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">Share</h1>
  </div>
);

const ViewButton = (id:any) => {

  // const viewHandler = (j:any) => {
  //   console.log("viewHandler jjjj:",j)
  //   window.location.href =  `${appUrl}/offer/${j}`;
  // //  handleCloseNavMenu();
  // };
    return(
    <div style={bottonWithBorderStyle} >
    <img
      src={Eyegrey}
      alt=""
      style={{ width: "15px", height: "15px" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">View</h1>
  </div>
  )}

  const bottonWithBorderStyle = {
    border: "1px solid var(--manatee)",
    borderRadius: "16px",
    paddingBlock: "4px",
    paddingInline: "12px",
    display: "flex",
    alignItems: "center",
  };
  const selectedSavebottonWithBorderStyle = {
    border: "1px solid var(--yellow)",
    background:'var(--yellow)',
    borderRadius: "16px",
    paddingBlock: "4px",
    paddingInline: "12px",
    display: "flex",
    alignItems: "center",
  };

const titleStyle = {
  left: "20px",
  top: "10px",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
};

const imageStyle = {
  width: "46px",
  height: "46px",
};

const oneLineText = {
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: "vertical",
};

const mapStateToProps = (state: any, props: any) => ({
  //offer: state.cartReducer.currentBrowsingProduct,
  ...props,
});

const mapDispatchToProps = (dispatch: any) => ({
  
  addFavourites: (i:any) => addFavourites(i),
  // globalySetCurrentBrowsingProduct: (offer: any) =>
  //   dispatch(setCurrentBrowsingProduct(offer)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OfferGridItem);
