import React from "react";
import { StarImage } from "../../../../../_assets/images";
import EmptyBox from "../../../../../_shared/components/empty_box";

export default function StaticCardListItem() {
  return (
    <div
      className="statistic-card-with-shadow"
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <EmptyBox size={12} left={true} />
      <img src={StarImage} />
      <EmptyBox size={12} left={true} />

      <div>
        <h1 className="baloo2-semi-bold-black-18px">Great Selections</h1>
        <h1 className="baloo2-normal-black-18px">
          We offer hundreds of products and services to choose from in lower
          prices as low as 25% to 50% off.
        </h1>
      </div>
      <EmptyBox size={12} left={true} />
    </div>
  );
}
