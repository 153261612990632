
import axios from 'axios'
import store from "./store"

// api base url
//export const API_BASE_URL = 'http://64.226.124.130/api/go'
export const API_BASE_URL22 = 'http://localhost:9001/api/go'
export const API_BASE_URL1 = 'https://161.97.91.128/api/go'
export const API_BASE_URL = 'https://golalitatwffer.com/api/go'

console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&:",store.store.getState() )
const token: string | null = localStorage.getItem("token");

if (token !== null) {
    // Token exists in localStorage
    console.log("test Token1:", token);
   // alert(token)
} else {
    // Token doesn't exist in localStorage
    console.log("test Token not found");
}
export const instance2 = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
     //// store.store.getState().authReducer.token, 
   'x-access-token':token,
     mode: 'no-cors'
  },
 // withCredentials:false,
})
const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    mode: 'no-cors'
  },
  withCredentials:false,
})

export default instance