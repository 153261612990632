export default function PopularCategoryTitle() {
  return (
    <div style={titleStyle}>
      <span className="baloo2-semi-bold-blackcurrant-36px">
        Popular Category
      </span>
    </div>
  );
}

const titleStyle = {
  display: "flex",
  marginTop: "32px",
  marginInline: "32px",
  alignItems: "center",
};
