import store from "../../store";
import { setOffers } from "../merchant-actions"
import { merchantApi } from "../merchant-api"

export const getOffers =  async (body:any,dispatch:any) => {
  //const token = store.store.getState().authReducer.token;
  const res = await merchantApi.getOffers(body)
  console.log('يا اخي ايش هذا'+res.data)
  dispatch(setOffers(res))
}
