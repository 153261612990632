import React, { useState,useEffect } from "react";
import EmptyBox from "../../_shared/components/empty_box";
import "./wishlist.css";
import WishlistList from "./components/wishlist_list";
import { connect } from "react-redux";
import WishlistHeader from "./components/wish_list_header";
import OfferOrProductTitle from "./components/offer_or_product_title";
import { appUrl } from "../../_shared/constants/app_urls";
import { StarFavotire as Eyegrey, User} from "../../_assets/images";

import { getFavourites } from "../../redux/merchant/thunks/wish_thunks";

import { tokenHandler  } from "../../services/services";

function Wishlist({ stores = [1, 2, 3, 4],favorites,getFavourites }: any) {
  const [isOfferSelected, setOfferSelected] = useState(true);
  console.log('worked:',favorites)

  useEffect(() => {
    tokenHandler();
    getFavourites();
  }, []);

  const viewHandler = () => {
    console.log("viewHandler :")
    window.location.href =  `${appUrl}/profile`;
  //  handleCloseNavMenu();
  };

  return (
    <div style={{ marginInline: "2vw" }}>
      {/* <OfferOrProductTitle
        isOfferSelected={isOfferSelected}
        setOfferSelected={setOfferSelected}
      /> */}
      <div style={bottonWithBorderStyle} onClick={() => viewHandler()}>
        <img src={User} alt="" style={{ width: "32px", height: "32px" }} />
        <EmptyBox size={2} />
        {/* <h1 className="baloo2-normal-manatee-12px">My Profile</h1> */}
      </div>
      <EmptyBox size={"6vh"} />
      <WishlistHeader />
      {(favorites && favorites.favorites == undefined) ||
      (favorites && favorites.favorites && favorites.favorites.length) === 0 ? (
        <>
          <EmptyBox size={"10vh"} />
          <span>No item found</span>
          <EmptyBox size={"10vh"} />
        </>
      ) : (
        <WishlistList stores={favorites && favorites.favorites} />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => (
  // console.log("state.merchantReducer.favorites:",state.merchantReducer),
  {
  favorites: state.merchantReducer.favorites,
});

const mapDispatchToProps = (dispatch: any) => ({
  getFavourites: () => getFavourites(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);

const bottonWithBorderStyle = {
//  border: "1px solid var(--manatee)",
  borderRadius: "16px",
 // paddingBlock: "4px",
  //paddingInline: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent:"right" ,
//  background:'red'
};
const selectedSavebottonWithBorderStyle = {
  border: "1px solid var(--yellow)",
  background:'var(--yellow)',
  borderRadius: "16px",
  paddingBlock: "4px",
  paddingInline: "12px",
  display: "flex",
  alignItems: "center",
};
