import React from "react";
import StaticCardListItem from "./static_card_list_item";

export default function StaticCardList() {
  return (
    <div className="statistic-grid">
      {[1, 2, 3, 4, 5, 6].map((s, index) => (
        <StaticCardListItem key={index} />
      ))}
    </div>
  );
}
