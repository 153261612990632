import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBanners } from "../../../../redux/home/thunks/banners_thunk";
import { logo, SliderImage } from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import PrimaryButton from "../../../../_shared/components/primary_button";
import { primaryColor } from "../../../../_theme/colors";
import "./home_slider_carousal.css";
import Tile from "./widgets/tile";

const images = [SliderImage, logo, SliderImage];

function HomeSliderCarousel({ banners, getBanners }: any) {
  var [currentIndex, setIndex] = useState(0);

  useEffect(() => {
    getBanners();
  }, []);
  useEffect(() => {
    setInterval(() => {
      if (banners.length === 0) return;
      if (currentIndex + 1 >= banners.length) {
        setIndex(0);
      } else {
        setIndex(currentIndex + 1);
      }
    }, 10000);
  }, []);
  return (
    <div>
      <div style={{ position: "relative" }}>
        {banners.length === 0 ? (
          <EmptyBox size="500px" />
        ) : (
          <img
            style={imageStyle as any}
            src={banners[currentIndex].link}
            alt=""
          />
        )}

        <div style={titleStyle as any}>
          <h1
            className="baloo2-semi-bold-gossamer-64px"
            style={{ color: "white" }}
          >
            Sale Summer Starts Today
          </h1>
        </div>
        <div style={buttonStyle as any}>
          {/* <PrimaryButton title="Shop Now" /> */}
        </div>
        <div style={dotRowsStyle as any}>
          {(banners as any[]).map((e, index) => (
            <div
              key={index}
              style={
                currentIndex === index ? selectedDotStyle : unSelectedDotStyle
              }
              onClick={() => setIndex(index)}
            ></div>
          ))}
        </div>
      </div>
          <Tile />

    </div>
  );
}

const mapStateToProps = (state: any) => ({
  banners: state.homeReducer.banners,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBanners: () => getBanners(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSliderCarousel);

const imageStyle = {
  width: "100%",
  height: "500px",
  objectFit: "cover",
};

const unSelectedDotStyle = {
  borderRadius: "500px",
  backgroundColor: "rgba(255, 255, 255, 1)",
  width: "16px",
  height: "16px",
  marginInline: "8px",
};

const selectedDotStyle = {
  boxShadow:
    "0px 0px 1.2701581716537476px rgba(140.00000685453415, 221.00000202655792, 173.00000488758087, 1)",
  borderRadius: "500px",
  backgroundColor: primaryColor,
  width: "16px",
  height: "16px",
  marginInline: "8px",
};

const dotRowsStyle = {
  bottom: "20px",
  position: "absolute",
  display: "flex",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "120px",
};

const titleStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: "40px",
  maxWidth: "380px",
  marginLeft: "auto",
  marginRight: "auto",
};

const buttonStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  bottom: "100px",
  maxWidth: "200px",
  marginLeft: "auto",
  marginRight: "auto",
};
