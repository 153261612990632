import React, { useEffect } from "react";
import EmptyBox from "../../../../_shared/components/empty_box";
import CategoriesList from "./widgets/categories_list";
import CategoriesStoreList from "./widgets/categories_store_list";
import CategoriesTitle from "./widgets/categories_title";
import { connect } from "react-redux";
import { getHomeCategories } from "../../../../redux/home/thunks/categories_thunks";

const Categories = ({ categories, getCategories }: any) => {
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <CategoriesTitle />
      <EmptyBox size="72px" />
      <CategoriesList list={categories} />
      <EmptyBox size="10px" />
      {/* <CategoriesStoreList list={categories} /> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.homeReducer.categories,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCategories: () => getHomeCategories(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
