import {applyMiddleware, combineReducers,} from "redux";

import thunk from "redux-thunk";
import {authReducer} from './auth/auth-reducer'
import {navReducer} from './nav/nav-reducer'
import {merchantReducer} from "./merchant/merchant-reducer";
import {cardReducer} from "./card/card-reducer";
import {loadersReducer} from "./loaders/loaders-reducer";
import { configureStore } from "@reduxjs/toolkit";
import localforage from 'localforage';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { homeReducer } from "./home/home_reducer";

storage.removeItem('root10');
storage.removeItem('root9');
storage.removeItem('root8');
storage.removeItem('root7');
storage.removeItem('root6');
storage.removeItem('root5');
storage.removeItem('root4');
storage.removeItem('root3');
storage.removeItem('root2');
storage.removeItem('root1');
storage.removeItem('root');
storage.removeItem('persist:root');

let reducers = combineReducers({
  authReducer,
  navReducer,
  merchantReducer,
  loadersReducer,
  homeReducer,
  cardReducer
})

const persistConfig = {
  key: 'a12xs',
  storage:localforage,
}


const persistedReducer = persistReducer(persistConfig, reducers)

  let store = configureStore({reducer:persistedReducer,enhancers:[applyMiddleware(thunk)]})
  let persistor = persistStore(store)
export default  { store, persistor }

// let store = configureStore({reducer:reducers,enhancers:[applyMiddleware(thunk)]})
// export default store
