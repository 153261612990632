import { useState } from "react";
import { KFCImage,Discountflag } from "../../../_assets/images";
import { primaryColor } from "../../../_theme/colors";

const images = [KFCImage, KFCImage, KFCImage];

export default function MerchantImages({ merchant }: any) {
  //console.log("MerchantImagesMerchantImages:",merchant)
  var [currentIndex, setIndex] = useState(0);
  return (
    <div style={{overflow:'hidden' }}>
      <div style={{ position: "relative",marginBottom:'51px' }}>
    {merchant && merchant.discount &&    <div style={{
                width: "230px",
                height: "80px",
                position: "absolute",
                left: "-24px",
                top: "-24px",
                zIndex:20,
             //   backgroundColor:'green'
              }}>
        <img src={Discountflag} alt="merchant Discountflag" style={{
                width: "100%",
                height: "100%",
               // position: "absolute",
                //left: "-24px",
                //top: "-24px",
                //zIndex:20,
             //   backgroundColor:'green'
              }}  />
                <span
    style={{
      position: "absolute",
      left: "50%", // Adjust position as needed
      top: "50%", // Adjust position as needed
      transform: "translate(-50%, -50%)", // Center the text horizontally and vertically
      zIndex: 1,
      color: "white", // Text color
      fontSize: "13px", // Text size
      fontWeight: "bold", // Text weight
      textShadow: "1px 1px 2px black", // Optional: Add text shadow
    }}
  >
   {merchant && merchant.discount}
  </span>
        </div>}
   
        <img
          style={imageStyle as any}
          src={merchant && merchant.merchBanner && merchant.merchBanner[currentIndex]}
          alt=""
        />
           <img
              alt="imag"
              src={merchant && merchant.merchLogo && merchant.merchLogo[0]}
              style={{
                width: "80px",
                height: "80px",
                position: "absolute",
                left: "20px",
                bottom: "-40px",
                zIndex:10,
            //    backgroundColor:'red'
              }}
            />
       {/* <div style={dotRowsStyle as any}>
          {(merchant.merchBanner as []).map((image, index) => (
            <div
              style={
                currentIndex === index ? selectedDotStyle : unSelectedDotStyle
              }
              key={index}
              onClick={() => setIndex(index)}
            ></div>
          ))}
        </div> */} 
        
      </div>
    </div>
  );
}

const imageStyle = {
  width: "100%",
  maxHeight: "90vh",
  objectFit: "cover",
};

const unSelectedDotStyle = {
  borderRadius: "500px",
  backgroundColor: "rgba(255, 255, 255, 1)",
  width: "16px",
  height: "16px",
  marginInline: "8px",
};

const selectedDotStyle = {
  boxShadow:
    "0px 0px 1.2701581716537476px rgba(140.00000685453415, 221.00000202655792, 173.00000488758087, 1)",
  borderRadius: "500px",
  backgroundColor: primaryColor,
  width: "16px",
  height: "16px",
  marginInline: "8px",
};

const dotRowsStyle = {
  bottom: "20px",
  position: "absolute",
  display: "flex",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "120px",
};

const titleStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: "40px",
  maxWidth: "380px",
  marginLeft: "auto",
  marginRight: "auto",
};

const buttonStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  bottom: "100px",
  maxWidth: "200px",
  marginLeft: "auto",
  marginRight: "auto",
};
