import "./statistics.css";
import StaticCardList from "./widgets/static_card_list";
import StatisticNumbers from "./widgets/statistic_numbers";

export default function Statistics() {
  return (
    <div>
      <StaticCardList />
      <StatisticNumbers />
    </div>
  );
}
