import React from "react";

export default function StatisticNumbers() {
  return (
    <div className="statistics-numbers-with-green-color">
      <div className="statistic-number-grid">
        {[1, 2, 3, 4, 5, 6].map((s, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            <h1 className="baloo2-semi-bold-gossamer-64px">1 250</h1>
            <h1 className="baloo2-normal-gossamer-24px">Discounts + Offers</h1>
          </div>
        ))}
      </div>
    </div>
  );
}
