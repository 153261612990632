
export const SET_USER = 'SET_USER'
export const SET_IS_LOGIN_ERROR = 'SET_IS_LOGIN_ERROR'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_WORK_STATUS = 'SET_WORK_STATUS'
export const SET_PUBLIC_ORGANIZATIONS = 'SET_PUBLIC_ORGANIZATIONS'

export const CONTENT_DISABLED = 'CONTENT_DISABLED'
export const APP_DISABLED = 'APP_DISABLED'
export const APP_ENABLED = 'APP_ENABLED'