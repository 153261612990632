import { useTranslation } from "react-i18next";
import { alpha, InputBase, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EmptyBox from "./empty_box";

export default function SearchField({ style, ...props }: any) {
  const { t } = useTranslation();
  return (
    <Search style={style}>
      <EmptyBox size={4} inline="true" />
      <SearchIcon htmlColor={"#d3d3d3"} />
      <StyledInputBase
        placeholder={`${t("search")}`}
        inputProps={{ "aria-label": `${t("search")}` }}
        onChange={props.handleInputChange}
      />
    </Search>
  );
}

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  display: "flex",
  borderRadius: "10px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  borderColor: "grey",
  alignItems: "center",
  borderBlockColor: "grey",
  border: "1px solid #d3d3d3",
  marginRight: theme.spacing(2),
  maxWidth: "320px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
