import React from "react";
import { Rating } from "@mui/material";
import { primaryColor } from "../../../_theme/colors";
import EmptyBox from "../../../_shared/components/empty_box";
import {
  EmailImage,
  GetDirectionImage,
  LocationImage,
  PhoneImage,
  SimulatMapImage,
} from "../../../_assets/images";
import MerchantMap from "./merchant_map";

export default function MerchantDetails({ merchant }: any) {
  const a= [
    {number :  "+33698490775",type: "phone",_id:"6593d8c2805389bcc6245f81"},
    {number :  "+33698490123",type: "phone",_id:"6593d8c2805389bcc6245e87"}
]



  return (
    <div className="merchant-details-layout">
      <div className="half-merchant-details-layout-1">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h1 className="baloo2-semi-bold-black-36px">
            {merchant.merchName}
          </h1>
          <EmptyBox size={36} />
          {/* <Rating
            name="read-only"
            value={Number.parseFloat(merchant.rating)}
            readOnly
            style={{ float: "right" }}
          /> */}
          
        </div>
        <EmptyBox size={"2vh"} />
        <span
          className="baloo2-semi-bold-black-24px"
          style={{
            borderBottom: `2px solid ${primaryColor}`,
          }}
        >
          Overview
        </span>
        <EmptyBox size={20} />
        <h1
          style={{ marginInline: "16px" }}
          className="baloo2-normal-manatee-18px"
          dangerouslySetInnerHTML={{ __html: merchant.merchDescrption }}
        ></h1>
      </div>
      <EmptyBox size={20} />
      <div className="half-merchant-details-layout-2">
        {/* <img src={SimulatMapImage} alt="merchant location" /> */}
        
      <div style={{backgroundColor:"red",height:'300px',width:'100%'}}>

      <MerchantMap mapmerchGeoLat={merchant.merchGeoLat}  mapmerchGeoLng={merchant.merchGeoLng}/>
      </div>
        <EmptyBox size={16} top={true} />
        <div className="merchant-details-layout">
          <div className="half-merchant-details-layout-1">
            <TitleWithIcon title={merchant.merchStreet} icon={LocationImage} />
            <EmptyBox size={8} top={true} />
            <TitleWithIconPhone title={merchant.merchPubContact} icon={PhoneImage} />
            <EmptyBox size={8} top={true} />
            <TitleWithIcon title={merchant && merchant.merchPubEmail && merchant.merchPubEmail[0]} icon={EmailImage} />
            <EmptyBox size={8} top={true} />
            <TitleWithIconWeburl title={merchant.merchWebsite} icon={EmailImage} />
          </div>
          <div
            className="half-merchant-details-layout-2"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <EmptyBox size={24} top={true} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton mapmerchGeoLat={merchant.merchGeoLat}  mapmerchGeoLng={merchant.merchGeoLng}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const iconStyle = {
  width: "16px",
  height: "20px",
};

const TitleWithIconPhone = ({ icon, title }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={icon} alt="merchant location" style={iconStyle} />
      <EmptyBox size={8} inline={true} />
      {title && title.map((i:any)=>
      <h1 className="baloo2-semi-bold-black-18px">{i.number}</h1>)}
    </div>
  );
};

const TitleWithIcon = ({ icon, title }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={icon} alt="merchant location" style={iconStyle} />
      <EmptyBox size={8} inline={true} />
      <h1 className="baloo2-semi-bold-black-18px">{title}</h1>
    </div>
  );
};

const TitleWithIconWeburl = ({ icon, title }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center",width:"100%" }}>
      <img src={icon} alt="merchant location" style={iconStyle} />
      <EmptyBox size={8} inline={true} />
      <a href={title} className="baloo2-semi-bold-black-18px" target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'break-word' }}>
    {title}
  </a>
    </div>
  );
};

const handleLinkClick = ({mapmerchGeoLat, mapmerchGeoLng}:any) => {
  // Open URL in a new tab
  window.open(`https://www.google.com/maps/dir/Current+Location/${mapmerchGeoLat},${mapmerchGeoLng}`);
  
  // OR
  // Navigate to a URL in the same tab
  // window.location.href = "https://www.example.com";
};

const CustomButton = ({mapmerchGeoLat, mapmerchGeoLng}:any) => (
  <div style={bottonWithBorderStyle as any} 
  onClick={()=>handleLinkClick({mapmerchGeoLat, mapmerchGeoLng})}
  >
    <img
      src={GetDirectionImage}
      alt=""
      style={{ width: "12px", height: "12px" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-caribbean-green-14px">Get Directions</h1>
  </div>
);

const bottonWithBorderStyle = {
  border: "2px solid var(--caribbean-green)",
  borderRadius: "24px",
  padding: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  maxWidth: "160px",
};
