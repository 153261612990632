import React from "react";
import { CategoryImage, MacdonalsImage } from "../../../../../_assets/images";
import EmptyBox from "../../../../../_shared/components/empty_box";

export default function CategoriesListItem({ index, item }: any) {
  return (
    <div key={item.id} style={listItemStyle as any}>
      <img
        src={item.categoryImg}
        alt="partner "
        style={{ height: 123, width: 123, objectFit: "cover" }}
      />
      <EmptyBox size="28px" />
      <div style={{ textAlign: "center" }}>
        <span className="baloo2-normal-manatee-18px">{item.categoryName}</span>
      </div>
    </div>
  );
}

const listItemStyle = {
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  alignContent: "center",
  minWidth: 150,
  paddingInlineEnd: "16px",
};
