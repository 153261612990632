import React, { useState } from "react";
import EventEmitter from "../../../../utils/EventEm";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

export default function CategoriesList({
  categories,
  isGrid,
  getCategories1,
  getStores,
  ...props
}) {
  console.log("CategoriesList props:",categories)

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const a = {
    marginRight: 16,
    width: "100%",
    height:"130px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
    overflow: "hidden",
    borderRadius: 11,
  };
  const b = {
    marginRight: 16,
    width: "200px",
    aspectRatio: 1,
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
    overflow: "hidden",
    borderRadius: 11,
  };
  //console.log("categories categories:", window.innerWidth );

  console.log(
    "categories XXXXXXXXXXXXXXX:",
    categories != [] &&
      categories[0] &&
      categories[0].parentCategory &&
      categories[0].parentCategory.length
  );
  return (
    <>
      {categories != [] &&
        categories[0] &&
        categories[0].parentCategory &&
        categories[0].parentCategory.length != 5 && (
          <div onClick={props.onClick} className="backButton">
            <h3>Back</h3>
          </div>
        )}
      <div className="container">
        <Swiper
          effect={"slide"}
          grabCursor={true}
          //centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          // pagination={true}
          modules={[EffectCoverflow]}
          //className="mySwiper"
          spaceBetween={20}
          style={isMobileView ?{width:window.innerWidth * .9,height:'170px'}:{}}
        >
          {categories.map((e, index) => (
            <SwiperSlide style={isMobileView ?{width:"110px",height:"140px",marginRight:9} : {}} >
              <div style={isMobileView ? a : b}>
                <img
                  src={e.categoryImg}
                  alt="slide_image"
                  onClick={() => {
                    EventEmitter.emit("categoryIdEmitt", {
                      idCategory: e._id,
                      parentCategory: e.parentCategory,
                    });
                  }}
                  style={{
                    objectFit: "fill", // width: '100%'//
                    width: "100%",
                    height: "70%",
                    aspectRatio: 1,
                    borderRadius: 0,
                  }}
                />
                <h6 style={{ paddingLeft:6}}>{e.categoryName}</h6>
              </div>
            </SwiperSlide>
          ))}

          {/* <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div> */}
        </Swiper>
      </div>
    </>
  );
}
