import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Logo } from "../../../_shared/logo";
import "./nav_bar.css";
import SearchField from "./widgets/nav_bar_search";
import { drawMenuIcon, logo } from "../../../_assets/images";
import EmptyBox from "../../../_shared/components/empty_box";
import { appUrl } from "../../../_shared/constants/app_urls";
import { connect } from "react-redux";

import { selectTab } from '../../../redux/nav/nav-actions';

function Navbar({  selectedTab, selectTab  }: any) {
  return <ResponsiveAppBar  selectedTab={selectedTab} selectTab={selectTab} />;
}


const pages = ["Home", "All_Offers",  "Merchants", "Card","Wishlist"//, "contact_us"
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar({  selectedTab, selectTab }: any) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const { t } = useTranslation();
  const handleTabClick = (page:string) => {
    selectTab(page);
    window.location.href = appUrl + '/' + page;
  };
  return (
    <div
      style={{
        display: "flex",
        minHeight: "65px",
        alignItems: "center",
        overflow: "clip",
      }}
    >
      <EmptyBox size={10} />
      <Logo />
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="home-nav-bar-hide-on-medium-screen"
      >
        <EmptyBox size={10} />
        {pages.map((page) => (
          // <a key={page} href={appUrl + "/" + page}>
          <div key={page} onClick={() => handleTabClick(page)}>
            {page === selectedTab
              ? selectedNavBar(t(page))
              : unSelectedNavBar(t(page))}
          </div>
        ))}
        <div className="home-nav-bar-hide-on-b-medium-screen">
          <div style={{ display: "flex", alignItems: "center" }}>
            <EmptyBox size={10} />
            {/* <SearchField /> */}
            <EmptyBox size={10} />
            {/* <Icon name="notification" url="notifications" /> */}
            {/* <Icon
              name="heart"
              style={{ marginInlineStart: "20px" }}
              url="wishlist"
            /> */}
            
            <EmptyBox size={20} flexGrow={1} />
            {/* <div className="group-33850">
              <div className="frame-33851">
                <div className="frame-33666" style={{ marginInlineEnd: "8px" }}>
                  <img className="ellipse-94" src={logo} alt="account_image" />
                </div>
                <div className="address">100 Points</div>
              </div>
            </div> */}
            <EmptyBox size={20} />
          </div>
        </div>
      </div>
      <div className="home-nav-bar-hide-on-b-medium-screen">
        <EmptyBox size={20} flexGrow={1} />
      </div>
      <div
        className="home-nav-bar-show-on-medium-screen"
        style={{ justifyContent: "end", alignItems: "end" }}
      >
        {SideMenu(handleOpenNavMenu, anchorElNav, handleCloseNavMenu)}
      </div>

      <Box >
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            // anchorEl={anchorElUser}
            // anchorOrigin={{
            //   vertical: "top",
            //   horizontal: "right",
            // }}
            // keepMounted
            // transformOrigin={{
            //   vertical: "top",
            //   horizontal: "right",
            // }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
    </div>
  );
}

function SideMenu(
  handleOpenNavMenu: React.MouseEventHandler,
  anchorElNav: Element | null,
  handleCloseNavMenu: React.MouseEventHandler
) {

  const mobileMenuIconHandler = (page:any) => {
    window.location.href = appUrl + "/" + page;
  //  handleCloseNavMenu();
  };
  return (
    <Box
      sx={{
        marginX: "max(1vw,1rem)",
        position: "absolute",
        right: "0px",
        top: "16px",
      }}
    >
      <img
        src={drawMenuIcon}
        style={{ height: "max(2rem,5vw)", width: "max(2rem,5vw)" }}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page) => (
          <MenuItem key={page} onClick={()=>mobileMenuIconHandler(page)}>
            <Typography textAlign="center">{page == "All_Offers" ? "All Offers" : page}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

function selectedNavBar(title: string) {
  if(title == "All_Offers"){
    title = "All Offers"
  }
  return (
    <div
      className="navbar-link-place home-nav-bar-hide-on-medium-screen"
      style={{ marginInline: "12px" }}
    >
      {title}
    </div>
  );
}

function unSelectedNavBar(title: string) {
  if(title == "All_Offers"){
    title = "All Offers"
  }
  return (
    <div
      className="navbar-link baloo2-semi-bold-manatee-18px home-nav-bar-hide-on-medium-screen"
      style={{ marginInline: "12px" }}
    >
   {title}
    </div>
  );
}

function Icon(props: any) {
  const { name, style } = props;
  return (
    <a href={appUrl + "/" + props.url}>
      <div style={style}>
        <div className={name}></div>
      </div>
    </a>
  );
}



const mapStateToProps = (state: any) => ({
  //cart: state.cartReducer.cartProducts,
  selectedTab: state.navReducer.selectedTab,
});

// const mapDispatchToProps = (dispatch: any) => ({
//   selectTab: () => selectTab(dispatch),
// });
const mapDispatchToProps = (dispatch: any) => ({
  selectTab: (tab: string) => dispatch(selectTab(tab)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Navbar);