import React from "react";
import BigScreenWishListItem from "./wish_list_item";

import OfferGridItem from "../../all_offers/components/all_offers_list/offer_grid_item";
import WishlistGridItem from "../components/wishlist_grid_item";

export default function WishlistList({ stores }: any) {
  
  //console.log("favoritesfavorites stores:",stores)
  return (
    <div className={"wishlist-grid-with-one-column"}>
      {stores && stores.length > 0 &&(stores as any[]).map((e, index) => (
           <WishlistGridItem
           key={e._id}
           index={index}
           offer={e}
        //   urlToGoWhenClicked = {urlToGoWhenClicked + "/" + e._id}    
         /> 
        // <BigScreenWishListItem />
      ))}
    </div>
  );
}
