import React from "react";
import { logo } from "../_assets/images";

export function Logo() {
  return (
    <div
      style={{
        display: "flex",
        minWidth: "max(120px,15vw)",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <img
        style={{ height: "max(2rem,3vw)", width: "max(2rem,3vw)" }}
        src={logo}
        alt="logo_image"
      />
      <h1
        className="baloo2-semi-bold-caribbean-green-36px"
        style={{
          fontSize: "max(2rem,3vw)",
        }}
      >
        GoLalita
      </h1>
    </div>
  );
}
