import React,{useEffect,useState} from "react";
import Home from "../pages/home/home";
import { useTranslation } from "react-i18next";
import "./App.css";
import { Provider } from "react-redux";
import store from "../redux/store";
import Navbar from "./compnents/navbar/navbar";
import { BrowserRouter, Route } from "react-router-dom";
import Merchants from "../pages/merchants/merchants";
import Invalid from "../../src/app/invalid";
import { Routes } from "react-router";
import Merchant from "../pages/merchant/merchant";
import Card from "../pages/card/card";
import Footer from "./compnents/footer/footer";
import AllOffers from "../pages/all_offers/all_offers";
import ForApplink from "../pages/forApplink/forApplink";
import Offer from "../pages/offer/offer";
import { PersistGate } from "redux-persist/integration/react";
import { ProtectedRoute } from "./compnents/protected_route";
import Login from "../pages/login/login";
import Wishlist from "../pages/wish_list/wishlist";
import Profile from "../pages/profile/profile";
import { getUserData } from "../redux/auth/thunks/auth_thunks";
import { API_BASE_URL } from "../redux/instance"; 
import { appUrl } from "../_shared/constants/app_urls";
import CacheLoading from "../CacheLoading";
import axios from 'axios'

import CacheBuster from 'react-cache-buster';
import Packagefile from '../../package.json';

function App() {
  
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {version} =Packagefile;
  //const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
 
  useEffect(() => {
    console.log("version:",version)
    //preventDefault();
        //      emptyCacheStorage();
  }, []); // Make sure to provide an empty dependency array to useEffect to run this effect only once

  useEffect(() => {
    setIsLoading(true)
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    
    // Get the value of the 'token' parameter
    const token = queryParams.get('token');
    
    // Do something with the token, such as storing it in state or local storage
    console.log('Tokenaaaaaaaaaaaaaa app,tsx:', token);
    if (token) {
      console.log('Token if app,tsx:');
      localStorage.setItem("token", token);
      axios.get(API_BASE_URL + "/get_user", {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': token,
        },
      })
      .then(response => {
        // Handle successful response
        console.log('Response:', response.data);
        if(response.data._id){
          setIsValid(true)
        }else{
          
    window.location.href =  `${appUrl}/invalid`;

          setIsValid(false)
        }
      })
      .catch(error => {
        setIsLoading(false)
        // Handle error
        console.log('Error res:', error.response.data.message);
        window.location.href =  `${appUrl}/invalid`;
        setIsValid(false)
      });
      console.log('Token 2 app,tsx:');
    }

    setIsLoading(false)
    // You may want to perform additional actions here, such as redirecting the user
    
  }, []); // Make sure to provide an empty dependency array to useEffect to run this effect only once

  // useEffect(() => {
    
  //   const messageListener = window.addEventListener('message', (nativeEvent) => {
  //     console.log("messagemessagemessage app.tsx",nativeEvent?.data);

  //   localStorage.setItem("token", nativeEvent?.data);
  //   });
  //   return messageListener;
  // }, []);
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  // if(!isValid) return(
  //   <div style={{ alignItems:'center',justifyContent:'center',alignSelf:'center',width:'100%',marginTop:200,display:'flex'}}>
  //     {isLoading? <h3 >Loading....</h3> :<h3 >Provided token is invalid !</h3>}
  //   </div>
  // )
  // else
  return (
    <div>
      {/* {!isLatestVersion ? (
        <p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              emptyCacheStorage();
            }}
          >
            Update version
          </a>
        </p>
      ): */}
        <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <div>
          <Navbar />
          <BrowserRouter>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/all_offers"
                element={
                  //<ProtectedRoute>
                  <AllOffers />
                  // </ProtectedRoute>
                }
              />
                 <Route
                path="/forApplink"
                element={
                  //<ProtectedRoute>
                  <ForApplink />
                  // </ProtectedRoute>
                }
              />
              
              <Route
                path="/profile"
                element={
                  //<ProtectedRoute>
                  <Profile />
                  // </ProtectedRoute>
                }
              />

<Route
                path="/invalid"
                element={
                  //<ProtectedRoute>
                  <Invalid />
                  // </ProtectedRoute>
                }
              />
              <Route
                path="/merchants"
                element={
                  //<ProtectedRoute>
                  <Merchants />
                  //</ProtectedRoute>
                }
              />
              <Route
                path="/card"
                element={
                  //<ProtectedRoute>
                  <Card />
                  //</ProtectedRoute>
                }
              />
              <Route
                path="/merchant/:id"
                element={
                  ///<ProtectedRoute>
                  <Merchant />
                  //</ProtectedRoute>
                }
              />
              <Route
                path="/Offer/:id"
                element={
                  ///<ProtectedRoute>
                  <Offer />
                  //</ProtectedRoute>
                }
              />

              <Route
                path="/wishlist"
                element={
                  // <ProtectedRoute>
                  <Wishlist />
                  // </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
          {/* <Footer /> */}
        </div>
      </PersistGate>
    </Provider>
    </CacheBuster>
    </div>
   
  );
}

export default App;
