import { ShippingIcon, SupportIcon,TotalIcon,DiscountIcon } from "../../../../../_assets/images";
export default function Tile() {
  return (
  <div className="bottom-slider-grid">

    <div style={tileStyle} >
      <div style={{ position: "relative", width: "64px", height: "64px" }}>
      <img src={ShippingIcon} />
      </div>
      <div style={titleStyle}>
        <span color="black" className="baloo2-semi-bold-black-18px">
         Get Free Shipping
        </span>
        <br />
        <span className="baloo2-normal-white-black-18px">
          Save QR 100 or more
        </span>
      </div>
    </div>
       <div style={tileStyle} >
        <div style={{ position: "relative", width: "64px", height: "64px" }}>
        <img src={SupportIcon} />
        </div>
        <div style={titleStyle}>
          <span color="black" className="baloo2-semi-bold-black-18px">
            Support Online
          </span>
          <br />
          <span className="baloo2-normal-white-black-18px">
            Call : +974 4400 8888
          </span>
        </div>
      </div> 
      <div style={tileStyle} >
        <div style={{ position: "relative", width: "64px", height: "64px" }}>
         <img src={DiscountIcon} />
        </div>
        <div style={titleStyle}>
          <span color="black" className="baloo2-semi-bold-black-18px">
              Discount
          </span>
          <br />
          <span className="baloo2-normal-white-black-18px">
             20% off on Selections
          </span>
        </div>
      </div> 

      <div style={tileStyle} >
        <div style={{ position: "relative", width: "64px", height: "64px" }}>
         <img src={TotalIcon} />
        </div>
        <div style={titleStyle}>
          <span color="black" className="baloo2-semi-bold-black-18px">
              Money Back
          </span>
          <br />
          <span className="baloo2-normal-white-black-18px">
            100% Money Guarantee
          </span>
        </div>
      </div> 
      
    </div>
  );
}

const tileStyle = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
  margin: "16px",
  padding: "16px",
};

const circleContainerStyle = {
  backgroundColor:
    "rgba(28.000000230968, 192.00000375509262, 160.00000566244125, 1)",
  width: "64px",
  height: "64px",
  borderTopLeftRadius: "100px",
  borderTopRightRadius: "100px",
  borderBottomLeftRadius: "100px",
  borderBottomRightRadius: "100px",
};

const circleContainerStyle2 = {
  width: "36px",
  height: "23.14285659790039px",
  position: "absolute",
  left: "14px",
  top: "20.428571701049805px",
};

const iconStyle = {
  backgroundColor: "rgba(255, 255, 255, 1)",
  width: "36px",
  height: "23.14285659790039px",
  position: "absolute",
  left: "0px",
  top: "0px",
};

const titleStyle = {
  alignItems: "center",
  justifyContent: "center",
  margin: "auto 0",
  paddingInline: "16px",
};
