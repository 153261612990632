import React from "react";





const CacheLoading = () => {
return <div> Fetching...</div>
};

export default CacheLoading;