import React, { useEffect, useState } from 'react';

import "./pagination.css";
function ForAppLink() {
  const [appOpened, setAppOpened] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  //http://golalitatwffer.com/ForApplink?email=test@gulf.com&password=abc123123
  // Get the value of the 'token' parameter
  const email = queryParams.get('email');
  const password = queryParams.get('password');
  const token = queryParams.get('GGeToken');
  //console.log("email*******************:",email,password,token)
  //const primaryUrl = "golalitagulfexchange://login?email=test@gulf.com&password=abc123123";
  //const fallbackUrlIos = "https://apps.apple.com/app/golalita-gulf-exchange/id6449490331?login?email=test@gulf.com&password=abc123123";
 // const fallbackUrlAndroid = "https://play.google.com/store/apps/details?id=com.golalitagulfexchange&hl=en&gl=US?login?email=test@gulf.com&password=abc123123";
 
 
 // Construct the URLs dynamically
const primaryUrl = `golalitagulfexchange://login?token=${encodeURIComponent(token)}`;
const fallbackUrlIos = `https://apps.apple.com/app/golalita-gulf-exchange/id6449490331?login?token=${encodeURIComponent(token)}`;
const fallbackUrlAndroid = `https://play.google.com/store/apps/details?id=com.golalitagulfexchange&hl=en&gl=US#login?token=${encodeURIComponent(token)}`;
// const primaryUrl = `golalitagulfexchange://login?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
// const fallbackUrlIos = `https://apps.apple.com/app/golalita-gulf-exchange/id6449490331?login?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
// const fallbackUrlAndroid = `https://play.google.com/store/apps/details?id=com.golalitagulfexchange&hl=en&gl=US?login?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
 
  // Function to detect if the device is iOS
   const isIos = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  };

    // Function to detect if the device is Android
    const isAndroid = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android/i.test(userAgent);
    };

     // Set the fallback URL based on the device type
  const fallbackUrl = isIos() ? fallbackUrlIos : (isAndroid() ? fallbackUrlAndroid : fallbackUrlIos);

  useEffect(() => {
    const openApp = () => {
      try {
          // Try to open the custom URL scheme
          window.location.href = primaryUrl;
      } catch (error) {
          // Handle error here
         // console.error("Error opening app:", error);
          // Set appOpened to true to trigger fallback action
          setTimeout(() => {
          setAppOpened(true);
        }, 2500);
      }
  
      // Set a timeout to check if the app opened successfully
      setTimeout(() => {
          // If the app didn't open, setAppOpened to true to trigger fallback action
          if (!document.hidden) {
              setAppOpened(true);
          }
      }, 2500); // Adjust the timeout value as needed
  };
    openApp(); // Call openApp function when component mounts

    // Clean up function (not necessary in this case)
    return () => {
      // Any cleanup code can go here
    };
  }, []); // Empty dependency array to run the effect only once on mount

  const handleButtonClick = () => {
    // Open the App Store URL when button is clicked
  // const applink = "https://apps.apple.com/app/golalita-rewards-and-discount/id1589276214"
    window.location.href = fallbackUrl;
  };

  return (
      <>
      <div className="hide-hamburger-menu">Hide Hamburger Menu</div>
      {appOpened ? (<div style={{padding:"10px"}}>
      
        <p>Please ensure you have the app installed and reload this page again.</p>

        <h3 style={{marginBottom:"5px",marginTop:"20px"}}>Steps to Download and Auto-Login</h3>
        <div>
        <ol>
          <li> Click the "Download Golalita | Gulf Exchange App" button below.</li>
          <li> Install the App from respective Stores.</li>
          <li> Once the download is complete, return to this page.</li>
          <li> And reload this page to automatically login.</li>
        </ol>
        </div>
        <button    style={{
        marginTop: "20px", 
        backgroundColor: "#1cc0a0", 
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer"
    }}  onClick={handleButtonClick}>Download Golalita | Gulf Exchange App</button>
      </div>
      ) : (
        <div style={{padding:"10px"}}>
        <p>Loading...</p>
        <div className="loading-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
        </div>
      )}
    </>
  );
}

export default ForAppLink;
