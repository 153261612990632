import React from "react";
import { useTranslation } from "react-i18next";
import {
  CherryPeople,
  DeliveryImage,
  DiscoverPlacesImage,
  GreatValueImage,
  LaptopWithCard,
} from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import PrimaryButton from "../../../../_shared/components/primary_button";
import "./get_rewards.css";
import RewardsCard from "./widgets/rewards_card";

export default function GetRewards() {
  const { t } = useTranslation();
  return (
    <div>
      <EmptyBox size="80px" />
      <div className="rewards">
        <div className="half_reward">
          <h1 className="baloo2-yellow-18px">Get Rewarded</h1>
          <EmptyBox size="20px" />
          <h1
            className="baloo2-semi-bold-black-36px"
            style={{ marginInlineEnd: "80px" }}
          >
            Earn more discounts up to 20% to 50% off on selections
          </h1>
          <EmptyBox size="20px" />
          <h1
            className="baloo2-normal-manatee-24px"
            style={{ marginInlineEnd: "64px" }}
          >
            Whatever you need, we offer a selection of items where you can go
            shopping online, avail services from our merchants, and experience
            getaways near you.
          </h1>
          <EmptyBox size="40px" />
          {/* <PrimaryButton title="Start Shopping" /> */}
        </div>
        <div className="half_reward">
          <EmptyBox size="12px" />
          <img
            style={{ width: "100%" }}
            src={LaptopWithCard}
            alt="laptop_with_card"
          />
        </div>
      </div>
      <div style={{ display: "block" }}>
        <EmptyBox size="100px" />

        <div className="rewardCard" style={{ paddingInline: "32px" }}>
          <RewardsCard
            image={GreatValueImage}
            title={t("great_value_title")}
            subTitle={t("great_value_sub_title")}
          />
          <RewardsCard
            image={CherryPeople}
            title={t("funny_experience_title")}
            subTitle={t("funny_experience_sub_title")}
          />
          <RewardsCard
            image={DeliveryImage}
            title={t("delivery_title")}
            subTitle={t("delivery_sub_title")}
          />
          <RewardsCard
            image={DiscoverPlacesImage}
            title={t("discover_places_title")}
            subTitle={t("discover_places_sub_title")}
          />
        </div>
      </div>
      <EmptyBox size="40px" />
    </div>
  );
}
