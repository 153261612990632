import { alpha, InputBase, styled } from "@mui/material";
import EmptyBox from "../../_shared/components/empty_box";
import PrimaryButton from "../../_shared/components/primary_button";
import { useState ,useEffect} from "react";
import { connect } from "react-redux";
import { updateUserData } from "../../redux/auth/thunks/auth_thunks";
import { useNavigate } from "react-router-dom";

import { getCardDetails } from "../../redux/merchant/thunks/card_thunks";

import { tokenHandler  } from "../../services/services";

function Profile({ updateUserData ,card,getCardDetails}: any) {
  console.log('Profile worked:',card)
  const navigate = useNavigate();
  const [userName, setUserName] = useState(card.username);
  const [email, setEmail] = useState(card.email);
  const [phoneNumber, setPhoneNumber] = useState(card.phone);


  useEffect(() => {
    tokenHandler();
    getCardDetails();
  }, []);
  const updateUserDataHandler = () => {
    console.log("updateUserData starts",userName,email,phoneNumber);
    updateUserData({
      username: userName,
      email: email,
      phone: phoneNumber,
    }).then((res:any) => {
      console.log("updateUserData res:",res)
      //navigate("/home");
      console.log("updateUserData after:",)
      
      // 
    });
  };

  return (
    <div>
      <EmptyBox size="40px" />
      <div style={{ display: "flex", margin:7, marginBottom:26 }}>
       <h3>UserId:<h2>{card.userId}</h2></h3>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField>
          <StyledInputBase
            value={userName}
            onChange={(eve) => {
              setUserName(eve.target.value);
            }}
            placeholder={`Name`}
            inputProps={{ "aria-label": `userName` }}
          />
        </TextField>
      </div>
      <EmptyBox size="20px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField>
          <StyledInputBase
            value={email}
            onChange={(eve) => {
              setEmail(eve.target.value);
            }}
            placeholder={`Email`}
            inputProps={{ "aria-label": `email` }}
          />
        </TextField>
      </div>
      <EmptyBox size="20px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField>
          <StyledInputBase
            type="Phone Number"
            value={phoneNumber}
            onChange={(eve) => {
              setPhoneNumber(eve.target.value);
            }}
            placeholder={`Phone Number`}
            inputProps={{ "aria-label": `phoneNumber` }}
          />
        </TextField>
      </div>
      <EmptyBox size="40px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          title="Update Profile"
          onClick={() =>updateUserDataHandler()
          }
        />
      </div>
      <EmptyBox size="40px" />
    </div>
  );
}

const mapStateToProps = (state:any) => ({
  card: state.merchantReducer.card,
});
const mapDispatchToProps = (dispatch: any) => ({
  updateUserData: (body: any) => updateUserData(dispatch, body),
  getCardDetails: () => getCardDetails(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

const TextField = styled("div")(({ theme }) => ({
  height: "40px",
  display: "flex",
  borderRadius: "10px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  borderColor: "grey",
  alignItems: "center",
  borderBlockColor: "grey",
  border: "1px solid #d3d3d3",
  paddingInlineStart: theme.spacing(2),
  width: "400px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      width: "240px",
    },
  },
}));