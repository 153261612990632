import React, { useEffect, useState } from "react";
import EmptyBox from "../../_shared/components/empty_box";
import ProductsList from "../_compnents/products/products_list";
import OfferOrProductTitle from "./compnents/offer_or_product_title";
import MerchantDetails from "./compnents/merchant_details";
import MerchantImages from "./compnents/merchant_images";
import BookingModal from './compnents/BookingModal';
import "./merchant.css";
import { useParams } from "react-router-dom";
import { getMerchantDetails,getMerchantProducts,enquiryMailer } from "../../redux/merchant/thunks/merchant_thunks";
import { connect } from "react-redux";
import { appUrl } from "../../_shared/constants/app_urls";

function Merchant({ merchant, getDetails,sendenquiryMailer,hotelSubCategories }: any) {
  console.log("merchant details:",merchant)
 console.log("merchant details - hotelSubCategories:",hotelSubCategories)
  const [isOfferSelected, setOfferSelected] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const params = useParams(); 


  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleBookingSubmit = (data:any) => {
    console.log('Booking Data:', data);
    
    data.Hotel = merchant.merchName;
    
   // data.product_price = 'Value2';
    sendenquiryMailer(data)
    // handle booking data, e.g., send it to an API
    closeModal();
  };


 useEffect(() => {
  setIsLoading(true)
  const User = () => {
    setUserId(''+params);
    console.log(params.id)
  }
  User ();
     getDetails(params.id)
    // getProducts(params.id)
     const intervalId = setInterval(() => {
      // Increment count by 1 on each interval
     setIsLoading(false)
    }, 1000); // Interval set to 1000 milliseconds (1 second)

    // Clean up function to clear interval when component unmounts or when dependency array changes
    return () => clearInterval(intervalId);
  },[]);
  const nameExists: boolean = hotelSubCategories.some((obj: any) => obj._id === merchant.merchCategory
    
);


  console.log("nameExistsnameExistsnameExists",nameExists); // Output: true

  return typeof merchant === "undefined" || merchant == null || merchant.lenght === 0 || isLoading ? (
   <div style={{ display:'flex',height:'453px',justifyContent:'center',alignItems:'center' }}>
    <span>Loading......</span>
   </div>
  ) : (
    <>
    <BookingModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onSubmit={handleBookingSubmit}
      />
    <div style={{ margin: "2vw" }}>
      
        <div className="card-with-shadow" style={{ padding: "16px" }}>
       {merchant && <MerchantImages merchant={merchant} />}
       
        
 {nameExists &&  <div style={{alignItems:'flex-end',justifyContent:'flex-end',display:'flex'}}>
   <div style={bottonWithBorderStyle as any} onClick={openModal} >
    <h1 className="baloo2-normal-caribbean-green-14px">Book now</h1>
  </div>
   </div>}
       {merchant && <MerchantDetails merchant={merchant} />}
      </div>
      <EmptyBox size={"6vh"} />
      {/* <OfferOrProductTitle
        isOfferSelected={isOfferSelected}
        setOfferSelected={setOfferSelected}
      /> */}
      <EmptyBox size={"6vh"} />
      
      {/* { typeof products === "undefined" || products == null || products.lenght === 0 ? (
            <EmptyBox size={"50vh"} />
          ) : (
            <ProductsList
       
        products = {products}
        isGrid={true}
        urlToGoWhenClicked={`${appUrl}/product`}
      />
          )} */}

    </div>
    </>
  );
}

const bottonWithBorderStyle = {
  border: "2px solid var(--caribbean-green)",
  borderRadius: "24px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  maxWidth: "100px",
};

const mapStateToProps = (state: any) => ({
  merchant: state.merchantReducer.merchantDetails,
 // products: state.merchantReducer.merchantProducts,
  hotelSubCategories: state.merchantReducer.hotelSubCategories
});

const mapDispatchToProps = (dispatch: any) => ({
  getDetails: (merchantId: any) =>getMerchantDetails(merchantId, dispatch),
  sendenquiryMailer: (body: any) =>enquiryMailer(body, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
