import {
  GridViewImage,
  HeartIcon,
  ListViewImage,
} from "../../../_assets/images";
import EmptyBox from "../../../_shared/components/empty_box";

export default function WishlistHeader() {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={HeartIcon} alt="heart icon" />
        <span
          style={{ paddingInline: "8px" }}
          className="baloo2-semi-bold-blackcurrant-36px"
        >
          My Wishlist
        </span>
        <EmptyBox size={12} />
        <div style={{ flexGrow: 1 }} />

        <EmptyBox size={8} />
      </div>
      <EmptyBox size={12} />
    </div>
  );
}
