import { setHomeCategories, setPopularCategories } from "../home_action"
import { homeApi } from "../home_api"

export const getHomeCategories = async (dispatch:any) => {

  const res = await homeApi.getCategories({
     "parentCategory":"subcategories"
    })
    dispatch(setHomeCategories(res.data))
  }

  
export const getHomePopularCategories = async (dispatch:any) => {
  const res = await homeApi.getPopularCategories({
          "parentCategory":"FALSE"
    })
    dispatch(setPopularCategories(res.data))
  }