

import { API_BASE_URL } from "../../src/redux/instance"; 
import { appUrl } from "../../src/_shared/constants/app_urls";

import axios from 'axios';
export const tokenHandler =()=>{


  console.log("tokenHandler servicesss&&&&&&&&&")

  const token: string | null = localStorage.getItem("token");
        axios.get(API_BASE_URL + "/get_user", {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'x-access-token': token,
          },
        })
        .then(response => {
          // Handle successful response
          console.log('Response:', response.data);
          if(response.data._id){
          }else{
            
      window.location.href =  `${appUrl}/invalid`;
          }
        })
        .catch(error => {
          // Handle error
          console.log('Error res:', error.response.data.message);
          window.location.href =  `${appUrl}/invalid`;
        });
      }