import React from "react";
import { appUrl } from "../../../../_shared/constants/app_urls";
import OffersList from "./offers_list";

export default function AllOffersList({ offers, isGrid }: any) {
  console.log('yazid offers.data', offers)
  return (
    <OffersList
      offers={offers}
      isGrid={isGrid}
      urlToGoWhenClicked={`${appUrl}/offer`}
    />
  );
}
