import React from "react";
import EmptyBox from "../../../../../_shared/components/empty_box";
import "./rewards_card.css";

export default function RewardsCard({ image, title, subTitle }: any) {
  return (
    <div style={rewardCardStyle}>
      <div className="card-with-shadow">
        <img
          alt=""
          src={image}
          className="reward-card-image"
          style={{ objectFit: "cover" }}
        />
        <div
          style={{
            marginInline: "16px",
          }}
        >
          <h1
            className="baloo2-semi-bold-black-24px"
            style={{ textAlign: "center" }}
          >
            {title}
          </h1>
          <EmptyBox size="12px" />
          <h1 className="baloo2-yellow-18px" style={{ textAlign: "center" }}>
            {subTitle}
          </h1>
        </div>
        <EmptyBox size="20px" />
      </div>
    </div>
  );
}

const rewardCardStyle = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
};
