import {
  SELECT_TAB
} from "./nav-types";

const initialState = {
  selectedTab:'home'
}


export const navReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SELECT_TAB: return {...state,selectedTab: action.payload};
    default: return state
  }
}