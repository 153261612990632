import React, { useEffect, useState } from "react";
import EmptyBox from "../../_shared/components/empty_box";
import OfferItem from "./compnents/offer_grid_item";
import "./offer.css";
import { useParams } from "react-router-dom";
import {
  getMerchantDetails,
  getMerchantProducts,
} from "../../redux/merchant/thunks/merchant_thunks";
import { getOffers } from "../../redux/merchant/thunks/offers_thunks";
import { connect } from "react-redux";
import { appUrl } from "../../_shared/constants/app_urls";

function Merchant({ offers, getOffers }: any) {
  console.log("offers details:", offers);
  const [isOfferSelected, setOfferSelected] = useState(true);
  const [userId, setUserId] = useState("");
  const params = useParams();
  console.log("params offer", params);
  const a = params.id;
  const b= offers && offers.data.data.findIndex((offer:any) => offer._id == params.id );
  const selectedOffer = offers.data.data[b];
  console.log("@@@@@@@@ selectedOffer", selectedOffer);
  useEffect(() => {
    getOffers();
   // console.log("worked" + offers);
    // const selectOfferById = ({offers}:any) => {
    //   // Use find() method to find the offer with the provided ID
    //   return offers.find((offer:any) =>
    //   //console.log("selectOfferById:iiii",offer)
    //    offer._id == a
    //    );
    // };
  }, []);

  return !true ? (
    <EmptyBox size="50vh" />
  ) : (
   
    <div style={{ margin: "2vw" }}>
      <div className="card-with-shadow" style={{ padding: "16px" }}>
        {/* <MerchantImages merchant={selectedOffer} /> */}
        <EmptyBox size={"4vh"} />
        <OfferItem offer={selectedOffer} />
      </div>
      <EmptyBox size={"6vh"} />

      {/* { typeof products === "undefined" || products == null || products.lenght === 0 ? (
            <EmptyBox size={"50vh"} />
          ) : (
            <ProductsList
      
        products = {products}
        isGrid={true}
        urlToGoWhenClicked={`${appUrl}/product`}
      />
          )} */}

    </div>
  );
}

const mapStateToProps = (state: any) => ({
  offers: state.merchantReducer.offers,
});

const mapDispatchToProps = (dispatch: any) => ({
  getOffers: (i:any) => getOffers(i,dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
