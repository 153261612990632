import React ,{useState} from "react";
import { GridViewImage, ListViewImage } from "../../../../_assets/images";

import EmptyBox from "../../../../_shared/components/empty_box";
import SearchField from "../../../../_shared/components/search_field";
import Filterby from "../all_offers_filters/filter_by";
import './all_offers_header.css';
export default function AllOffersHeader({ onShowAsGridClicked,...props }: any) {

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  console.log("isMobileView:",isMobileView)
  const handleSearch = (i: any) => {
    // Implement your search logic here using the 'searchValue'
    console.log(`Searching for: ${i}`);
    // You can replace the console.log statement with your actual search functionality
  };
  return (
   <>
   {isMobileView ?  <div>
      <div style={{ display: "flex", alignItems: "center", justifyContent:'space-between' }}>
        <span
          style={{ paddingInline: "8px", fontSize: "18px"  }}
          className="baloo2-semi-bold-blackcurrant-36px"
        >
          All Offers
        </span>
        <EmptyBox size={12} />
        <SearchField
          handleInputChange={(i: any) => props.handleSearch(i.target.value)}
        /> 
        {/* <Filterby/> */}
        <div style={{ flexGrow: 1 }} className="hide-on-small-screen" />
       
      </div>
      <EmptyBox size={12} />
    </div>:
     <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{ paddingInline: "8px" }}
          className="baloo2-semi-bold-blackcurrant-36px"
        >
          All Offers
        </span>
        <EmptyBox size={12} />
        <SearchField
          handleInputChange={(i: any) => props.handleSearch(i.target.value)}
        />
        <div style={{ flexGrow: 1 }} />
        <img
          src={GridViewImage}
          alt="grid_view_image"
          style={{ cursor: "pointer" }}
          onClick={() => {
            onShowAsGridClicked(true);
          }}
        />
        <EmptyBox size={8} />
        <img
          src={ListViewImage}
          alt="lsit_view_image"
          style={{ cursor: "pointer" }}
          onClick={() => onShowAsGridClicked(false)}
        />
        <EmptyBox size={8} />
      </div>
      <EmptyBox size={12} />
    </div>
    }
   </>
  );
}


