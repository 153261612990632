import React from "react";
import OfferGridItem from "./offer_grid_item";
import "./offers.css";

export default function OffersList({
  offers,
  isGrid,
  urlToGoWhenClicked,
}: any) {
  console.log('yazid:11',offers)
  return (
    <div className={isGrid ? "products-grid" : "products-grid-with-one-column"}>
        {offers.length > 0 &&(offers as any[]).map((e, index) => (
         <OfferGridItem
         key={e._id}
         index={index}
         offer={e}
         urlToGoWhenClicked = {urlToGoWhenClicked + "/" + e._id}    
       /> 
      ))}
    </div>
  );
}
