import {
  SET_CARD,
} from "./card-types";

const initialState = {
  card: []
}

export const cardReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_CARD: return {...state, card: action.card}
    default: return state
  }
}
