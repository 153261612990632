import instance from "../instance";
import {instance2} from "../instance";
import axios from 'axios'


const authApi = {
  login: (body) => instance.post('/auth/signin', body),
  logout: (body) => instance.post('/user/delete_token', body),
  updateProfile: (userId, body) => instance.post(`/res.users/update/${userId}`, body),
  getUserData: () => instance2.get('/get_user'),
  updateUserData: (body) => instance2.patch('/user/aldaruserupdate',body),
  contactUs: (body) => instance.post('/loyalty.contact.us/create', body),
  resetPassword: (body) => instance.post('https://www.golalita.com/mobile/reset_password', body),
  getPublicOrganizations: () => instance.post('/user/org/lists/v2', {}),
  checkCode: (body) => instance.post('/code/check/v2', body),
  register: (body) => instance.post(`https://www.golalita.com/organisation/employee/registration/v2`, body),
  sendOTP: (body) => instance.post('/send/otp', body),
  sendOTPRegister: (body) => instance.post('/send/otp/new_user', body),
  verify: (body) => instance.post('/otp/verify', body),
  verifyRegister: (body) => instance.post('/otp/verify/new_user', body),
  changePassword: (body) => instance.post('/create/otp/password', body),
  getAppStatus: () => axios.get('https://keepcalmlabs.com/wp-json/wp/v2/apps/401')
}

export default authApi
