import {
  SET_ADVERT,
  SET_CATEGORIES,SET_HOTEL_SUB_CATEGORIES, SET_CURRENT_VIDEO, SET_FAVORITE, SET_INTERNATIONAL_CLIENTS, SET_LOCAL_CLIENTS,
  SET_MERCHANT_DETAILS,
  SET_MERCHANT_OFFERS, SET_MERCHANT_PRODUCTS,
  SET_MERCHANTS,
  SET_OFFERS, SET_CARD,SET_FAVORITES, SET_ORGANIZATIONS, SET_PREMIUM_BANNERS,
  SET_PREMIUM_MERCHANTS, SET_SOCIAL_MEDIA,
  SET_ORGANIZATIONS_DETAILS
} from "./merchant-types";

const initialState = {
  merchants: [],
  card: [],
  premiumMerchants: [],
  offers: [],
  favorites: [],
  merchantOffers: [],
  categories: [],
  hotelSubCategories: [],
  merchantDetails: [],
  merchantProducts: [],
  favoriteOffers: [],
  premiumBanners: [],
  localClients: [],
  internationalClients: [],
  organizations: [],
  organizationDetails: null,
  advert: null,
  currentVideo: 0,
  socialMedia: null
}

export const merchantReducer = (state = initialState, action) => {
  console.log("merchantReducer",action)
  switch (action.type) {
    case SET_MERCHANTS: return {...state, merchants: action.merchants}
    case SET_PREMIUM_MERCHANTS: return {...state, premiumMerchants: action.premiumMerchants}
    case SET_OFFERS: return {...state, offers: action.offers}
    case SET_CARD: return {...state, card: action.card}
    case SET_FAVORITES : return {...state, favorites: action.i}
    case SET_MERCHANT_OFFERS: return {...state, merchantOffers: action.merchantOffers}
    case SET_CATEGORIES: return {...state, categories: action.categories}
    case SET_HOTEL_SUB_CATEGORIES: return {...state, hotelSubCategories: action.hotelSubCategories}
    case SET_MERCHANT_DETAILS: return {...state, merchantDetails: action.merchantDetails}
    case SET_MERCHANT_PRODUCTS: return {...state, merchantProducts: action.merchantProducts}
    case SET_FAVORITE: return {...state, favorite: action.favorite}
    case SET_PREMIUM_BANNERS: return {...state, premiumBanners: action.premiumBanners}
    case SET_LOCAL_CLIENTS: return {...state, localClients: action.localClients}
    case SET_INTERNATIONAL_CLIENTS: return {...state, internationalClients: action.internationalClients}
    case SET_ORGANIZATIONS: return {...state, organizations: action.organizations}
    case SET_ADVERT: return {...state, advert: action.advert}
    case SET_CURRENT_VIDEO: return {...state, currentVideo: action.currentVideo}
    case SET_SOCIAL_MEDIA: return {...state, socialMedia: action.socialMedia}
    case SET_ORGANIZATIONS_DETAILS: return {...state,organizationDetails:action.organizationDetails}
    default: return state
  }
}
