import React, { useEffect, useState } from "react";
import "./card.css";
import { connect } from "react-redux";
import EmptyBox from "../../_shared/components/empty_box";
//import { getCardDetails } from "../../redux/card/card_thunks";
import Barcode from 'react-barcode';
import { getCardDetails } from "../../redux/merchant/thunks/card_thunks";

import { tokenHandler  } from "../../services/services";

import { CardBg } from "../../_assets/images";
function Card({ card, getCardDetails }) {
  const [showAsGrid, setShowAsGrid] = useState(true);
  useEffect(() => {
    tokenHandler();
    getCardDetails();
    console.log('worked:',card)
  }, []);

  const formattedDate = new Date(card && card.exp_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }).replace(/\//g, '/');

  const containerStyle = {
    // Set the background image
    backgroundImage:`url(${CardBg})` ,
    // Set other styles like width, height, position, etc. as needed
    width: '100%',
    maxWidth: '600px',
    aspectRatio:1.65,
    backgroundSize:'contain',
    position:'relative',
   // backgroundSize: 'contain', // You can adjust this based on your needs
    display: 'flex',
   // justifyContent: 'center',
   // alignItems: 'center',
    flexGrow:1,
    margin:12,
    borderRadius:11,
    padding:16,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)'
  };
  
  return (
    <div style={{// marginInline: "2rem"
    }}>
    <EmptyBox size={"2rem"} />
    <div style={{ display: "flex",alignItems:'center',justifyContent:'center' }}>
      <div style={containerStyle}>
        {/* Your content goes here */}
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: '45%', // Move the element down by 50% of the parent's height
            left: '50%', // Move the element right by 50% of the parent's width
            transform: 'translate(-50%, -50%)', // Center the element
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1 className="valueName">{card && card.username || "Golalita Customer"}</h1>
        </div>
       <div  style={{
        display:"flex",
        flexDirection:'column',
            overflow:'hidden',
           // alignSelf:'flex-start',
            height:'100%',
            width:'70%',
            justifyContent:'space-between',
           // background:'red'
          }}>
       <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <div style={{ alignItems: "flex-start", flex: 1 }}>
            <h1 className="titleDetails">Available Points</h1>
            <h1 className="valueDetails">{card && card.point_loly}</h1>
          </div> */}
        </div>
      
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"// ,background:'red'
          }}
        >
          <div style={{ alignItems: "flex-start", flex: 1}}>
            <div>
              <h1 className="titleDetails">Exp</h1>

              <h1 className="valueDetails">{card && formattedDate}</h1>
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>
    
    <div style={{ display: "flex",width:'100%',alignItems: 'center', justifyContent: 'center',
    //background:"red",
    marginBottom:100  }}>
    <Barcode value={card && card.codebare
    } height={50} />
    </div>

  </div>
  );
}

const mapStateToProps = (state) => ({
  card: state.merchantReducer.card,
});

const mapDispatchToProps = (dispatch) => ({
  getCardDetails: () => getCardDetails(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
