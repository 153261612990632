import {
  AboutUsImage,
  AppStoreImage,
  GolalitaAppPic,
  GooglePlayImage,
  LaptopWithCard,
} from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import PrimaryButton from "../../../../_shared/components/primary_button";
import "./about_us.css";

export default function HomeAboutUs() {
  return (
    <div>
      <EmptyBox size="80px" />
      <div className="about_us">
        <div className="half_about_us">
          <h1 className="baloo2-yellow-18px">About Us</h1>
          <EmptyBox size="12px" />
          <h1 className="baloo2-semi-bold-black-36px">Welcome to Golalita </h1>
          <EmptyBox size="32px" />
          <h1 className="baloo2-normal-manatee-24px">
            Golalita offers you great products at unbeatable prices, getaways
            that are unforgettable and services that you will experience at all
            times. <br />
            <br /> Whatever you need, we make things happen from the comfort of
            your home, straight to real-life environment, we pride our selves to
            bring you the best we offer.
          </h1>
          <EmptyBox size="20px" />
          {/* <PrimaryButton title="Shop Now" /> */}
        </div>
        <div className="half_about_us">
          <EmptyBox size="20px" />
          <img style={{ width: "100%" }} src={AboutUsImage} alt="AboutUs" />
        </div>
      </div>
      <EmptyBox size="140px" />
      <div className="about_us">
        <div className="half_about_us">
          <img
            style={{ width: "100%" }}
            src={GolalitaAppPic}
            alt="laptop_with_card"
          />
        </div>
        <EmptyBox size="20px" />
        <div className="half_about_us">
          <h1 className="baloo2-yellow-18px">About Us</h1>
          <EmptyBox size="12px" />
          <h1 className="baloo2-semi-bold-black-36px">
            Mobile Application for IOS & Android{" "}
          </h1>
          <EmptyBox size="32px" />
          <h1 className="baloo2-normal-manatee-24px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            aliquet purus id in facilisi fringilla tellus nunc rhoncus. Fames
            faucibus aliquam sagittis, adipiscing massa. Euismod ut tortor
            suspendisse phasellus integer{" "}
          </h1>
          <EmptyBox size="20px" />
          <div style={{ display: "flex" }}>
            <img
              src={GooglePlayImage}
              style={{ width: 140, height: 48 }}
              alt=""
            />
            <EmptyBox size="20px" />
            <img
              src={AppStoreImage}
              alt=""
              style={{ width: 140, height: 48 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
