import React,{useState} from "react";
import BigScreenMerchantsHeader from "./big_screen_merchants_header";
import SmallScreenMerchantsHeader from "./small_screen_merchants_header";

import { GridViewImage, ListViewImage } from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import SearchField from "../../../../_shared/components/search_field";
export default function MerchantsHeader({ onShowAsGridClicked,...props }: any) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  return (
    <div style={{ }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{ paddingInline: "8px" }}
        className="baloo2-semi-bold-blackcurrant-36px"
      >
        Merchants
      </span>
      <EmptyBox size={12} />
      <SearchField
          handleInputChange={(i: any) => props.handleSearch(i.target.value)}
        />
      <div style={{ flexGrow: 1 }} />
     { ! isMobileView &&  <> <img
        src={GridViewImage}
        alt="grid_view_image"
        style={{ cursor: "pointer" }}
        onClick={() => {
          onShowAsGridClicked(true);
        }}
      />
      <EmptyBox size={8} />
      <img
        src={ListViewImage}
        alt="lsit_view_image"
        style={{ cursor: "pointer" }}
        onClick={() => onShowAsGridClicked(false)}
      />
      <EmptyBox size={8} /> </>}
    </div>
    <EmptyBox size={12} />
  </div>
  );
}
