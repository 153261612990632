export default function CategoriesTitle() {
  return (
    <h1
      style={{ paddingInlineStart: "32px" }}
      className="baloo2-semi-bold-blackcurrant-36px"
    >
      Category of Merchant
    </h1>
  );
}
