import store from "../../store";
import { setCard } from "../merchant-actions"
import { merchantApi } from "../merchant-api"

export const getCardDetails =  async (dispatch:any) => {
  //const token = store.store.getState().authReducer.token;
  const res = await merchantApi.getCardDetails({
  })
  console.log("getCardDetails",res.data)
  dispatch(setCard(res.data))
}
