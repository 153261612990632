import React from "react";
import {
  Burger,
  CherryPeople,
  LaptopWithCard,
} from "../../../../../_assets/images";
import "./popular_category_grid.css";
import PopularCategoryGridItem from "./popular_category_grid_item";

export default function PopularCategoryGrid({ list }: any) {
  return (
    <div className="popular-category-grid">
      {(list as []).map((item, index) => (
        <PopularCategoryGridItem
          key={index}
          category={item}
          className={
            (index + 1) % 3 === 0 ? "popular-category-grid-item-with-2row" : ""
          }
        />
      ))}
    </div>
  );
}
