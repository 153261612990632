
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import AllOffersList from "./components/all_offers_list/all_offers_list";

import "./pagination.css";
// Example items, to simulate fetching from another resources.
//const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const itemsPerPage = 20;
function Items({ currentItems, showAsGrid }) {
    console.log("PaginatedItems currentItems:",currentItems)
  return (
    <>
      {currentItems &&
        <AllOffersList offers={currentItems}
         isGrid={showAsGrid} />
        }
    </>
  );
}

export default function PaginatedItems({ ...props }) {
    console.log("PaginatedItems stores:",props)
    const tot = props.stores.data && props.stores.data.total ||0;
    const items = props.stores.data ? props.stores.data.data : props.stores;
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil((props.stores.data && props.stores.data.total || tot) / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log(
        `handlePageClick ${event.selected}`
      );
      props.onPress(event.selected);
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} isGrid={props.showAsGrid} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="  >  "
        onPageChange={(i)=>handlePageClick(i)}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="  <  "
        renderOnZeroPageCount={null}
        activeClassName="active-page"
        containerClassName="pagination-container"
      />
    </>
  );
}

// // Add a <div id="container"> to your HTML to see the component rendered.
// ReactDOM.render(
//   <PaginatedItems itemsPerPage={4} />,
//   document.getElementById('container')
// );