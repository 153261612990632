import { alpha, InputBase, styled } from "@mui/material";
import EmptyBox from "../../../_shared/components/empty_box";
import PrimaryButton from "../../../_shared/components/primary_button";
import { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../../redux/auth/thunks/auth_thunks";
import { useNavigate } from "react-router-dom";

function LoginForm({ login }: any) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const loginHandler = () => {
    console.log("loginHandler starts");
    login({
      login: email,
      password,
      //TODO change this
      device_id: "sadsdsads",
      device_token: "sddsf",
      device_type: "WEB",
    }).then((res:any) => {
      console.log("loginHandler res:",res)
      navigate("/home");
      console.log("loginHandler after:",)
      
      // 
    });
  };

  return (
    <div>
      <EmptyBox size="40px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField>
          <StyledInputBase
            value={email}
            onChange={(eve) => {
              setEmail(eve.target.value);
            }}
            placeholder={`email`}
            inputProps={{ "aria-label": `email` }}
          />
        </TextField>
      </div>
      <EmptyBox size="20px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField>
          <StyledInputBase
            type="password"
            value={password}
            onChange={(eve) => {
              setPassword(eve.target.value);
            }}
            placeholder={`password`}
            inputProps={{ "aria-label": `password` }}
          />
        </TextField>
      </div>
      <EmptyBox size="40px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          title="Login"
          onClick={() =>loginHandler()
          }
        />
      </div>
      <EmptyBox size="40px" />
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  login: (body: any) => login(dispatch, body),
});

export default connect(null, mapDispatchToProps)(LoginForm);

const TextField = styled("div")(({ theme }) => ({
  height: "40px",
  display: "flex",
  borderRadius: "10px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  borderColor: "grey",
  alignItems: "center",
  borderBlockColor: "grey",
  border: "1px solid #d3d3d3",
  paddingInlineStart: theme.spacing(2),
  width: "400px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      width: "240px",
    },
  },
}));