import React from "react";
import MerchantsGridItem from "./merchants_grid_item";
import "./merchants_list.css";
export default function MerchantsList({ stores, isGrid }: any) {
  return (
    <div
      className={isGrid ? "merchants-grid" : "merchants-grid-with-one-column"}
    >
      {(stores as any[]).map((e, index) => (
        <MerchantsGridItem store={e} index={index} />
      ))}
    </div>
  );
}
