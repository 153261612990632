import Rating from "@mui/material/Rating";
import {
  Burger,
  BurgerKing,
  KentakyImage,
  StarFavotire as StarFavotireImage,
  StarImage,Discountflag
} from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import { appUrl } from "../../../../_shared/constants/app_urls";

import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay ,EffectCoverflow, Pagination } from 'swiper/modules';
export default function MerchantsGridItem({ store, index }: any) {
  //console.log("MerchantsGridItem store:",store)
  return (
    <a href={`${appUrl}/merchant/${store._id}`}>
        <div style={cardStyle}>
          <div
            className="merchants-card-with-shadow"
            style={{ position: "relative",overflow:'hidden' }}
          >
            {store.discount && <div style={{
                width: "230px",
                height: "80px",
                position: "absolute",
                left: "-25px",
                top: "-24px",
                zIndex:20,
             //   backgroundColor:'green'
              }}>
        <img src={Discountflag} alt="merchant Discountflag" style={{
                width: "100%",
                height: "100%",
               // position: "absolute",
                //left: "-24px",
                //top: "-24px",
                //zIndex:20,
             //   backgroundColor:'green'
              }}  />
                <span
    style={{
      position: "absolute",
      left: "50%", // Adjust position as needed
      top: "50%", // Adjust position as needed
      transform: "translate(-50%, -50%)", // Center the text horizontally and vertically
      zIndex: 1,
      color: "white", // Text color
      fontSize: "13px", // Text size
      fontWeight: "bold", // Text weight
      textShadow: "1px 1px 2px black", // Optional: Add text shadow
    }}
  >
   {store.discount}
  </span>
        </div>}
            <img
              alt="imag"
              src={store.merchLogo[0]}
              style={{
                width: "80px",
                height: "80px",
                position: "absolute",
                left: "20px",
                top: "190px",
                zIndex:10
              }}
            />
           {/* <img
              alt="store"
              src={store.image_url}
              style={{
                width: "40px",
                height: "40px",
                position: "absolute",
                right: "20px",
                top: "150px",
              }}
            /> */}
            {/* <img
              alt="store"
              src={store.merchBanner[0]}
              className="merchants-card-image"
              style={{ objectFit: "cover" }}
            /> */}
          {/* <img
              alt="store"
              src={store.merchBanner[0]}
              className="merchants-card-image"
              style={{ objectFit: "cover" }}
            /> */}
            <Swiper
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      effect="fade"
      spaceBetween={0}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView="auto"
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      modules={[Autoplay,EffectCoverflow]}
      className="mySwiper"
    >
      {store.merchBanner.map((e: any, index: any) => (
        <SwiperSlide key={index} style={{width: '100%'}}>
          {/* <div style={{backgroundColor:'red',height: '70%',
           width: '100%',marginRight:5,padding:4}}> */}
            <img
              alt={`merch-slide-${index}`}
              src={e}
              style={{ objectFit:'cover', width: '100%'//
              , height: '100%',borderRadius:0
             }}
            />
          {/* </div> */}
        </SwiperSlide>
      ))}
    </Swiper>
            <div
              style={{
                display: "flex",
                marginTop: "32px",
                marginBottom: "16px",
                marginInline: "8px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1
                className="baloo2-semi-bold-black-24px"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  textAlign: "start",
                }}
              >
                {store.merchName}
              </h1>
              <Rating
                name="read-only"
                value={store.rating}
                readOnly
                style={{ float: "right" }}
              />
            </div>
          </div>
        </div>
     
    </a>
  );
}

const cardStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "8px",
};

const textStyleElippesWithTowLines = {
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
};

const CustomButton = () => (
  <div style={bottonWithBorderStyle}>
    <img
      src={StarFavotireImage}
      alt=""
      style={{ width: "12px", height: "12px" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">Save</h1>
  </div>
);

const bottonWithBorderStyle = {
  border: "1px solid var(--manatee)",
  borderRadius: "16px",
  paddingBlock: "4px",
  paddingInline: "12px",
  display: "flex",
  alignItems: "center",
};
