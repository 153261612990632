import React,{useEffect,useState} from "react";
import { connect } from "react-redux";
import { StarFavotire as StarFavotireImage,Redhearticon,shareIcon,ShowIcon,Eyegrey,Eyewhite,Heartgrey,Heartwhite } from "../../../_assets/images";
import EmptyBox from "../../../_shared/components/empty_box";
import { appUrl } from "../../../_shared/constants/app_urls";

import {
  getMerchantDetails,
  getMerchantProducts,
} from "../../../redux/merchant/thunks/merchant_thunks";
import "../offer.css";
import AllSvg from "../../assets/categ_all.svg";

function OfferGridItem({
  offer,
  getDetails,
  merchant
}: any) {
  const [isSaved, setIsSaved] = useState(false);
  console.log("offerofferoffer:",offer)
  useEffect(() => {
    if (offer) {
      getDetails(offer && offer.merchant);
    }
  }, [offer])
  
  const saveHandler = () => {
    console.log("saveHandler")
    setIsSaved(!isSaved);
  };
  
  
  console.log("merchantmerchant:",merchant)
  const viewHandler = (j:any) => {
    console.log("viewHandler jjjj:",j)
    window.location.href =  `${appUrl}/offer/${j}`;
  //  handleCloseNavMenu();
  };

  const GotoButton = () => (
    <div style={gotobotton} 
    onClick={gotoButtonHandler}>
      <h1 className="baloo2-normal-manatee-14px">Go to Merchant details</h1>
    </div>
  );

  
const gotoButtonHandler = () => {
  console.log("gotoButtonHandler pressed")
  if(merchant){
    window.open(merchant.merchWebsite);
  
  }

};

  return (
    <div style={cardStyle}>
      <div
        className="products-grid-card-with-shadow"
        style={{ paddingInline: "16px" }}
      >
        <div style={titleStyle}>
          <img alt="" src={merchant && merchant.merchLogo && merchant.merchLogo[0]} style={imageStyle} />
          <EmptyBox size={8} inline="true" />
            <h1
              className="baloo2-semi-bold-black-18px"
              style={oneLineText as any}
            >
            {offer && offer.merchName}
            </h1>
            
          <EmptyBox size={12} inline="true" />
          <div style={{justifyContent:'flex-end'}}>
          {/* <ShareButton /> */}
          </div>
        </div>
        <EmptyBox size={8} />

        <img
          src={offer && offer.offerImage}
          className="offer-grid-card-image"
          alt=""
        />
      
        <EmptyBox size={4} />
        <hr style={{ borderTop: "0.5px solid #c3c3c3" }} />
        <EmptyBox size={4} />
        <h1 className="baloo2-semi-bold-black-14px" style={oneLineText as any}>
          Description:
        </h1>

        <h1
          className="baloo2-normal-manatee-14px"
          style={textStyleElippesWithTowLines as any}
        >
          {offer && offer.offerName}
        </h1>
        <EmptyBox size={12} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={isSaved ? selectedSavebottonWithBorderStyle : bottonWithBorderStyle} onClick={() => saveHandler()}>
            <img
              src={isSaved?Heartwhite :Heartgrey}
              alt=""
              style={{ width: "15px", height: "15px"  }}
            />
            <EmptyBox size={2} />
            {isSaved ?<h1 className="baloo2-normal-white-12px">Save</h1>:<h1 className="baloo2-normal-manatee-12px">Save</h1>}
          </div>
         
          {/* <ViewButton id={offer._id} /> */}
        </div>
        <EmptyBox size={12} />
          <GotoButton />
      </div>
    </div>
  );
}

const cardStyle = {
  display: "flex",
  justifyContent: "center",
 // padding: "8px",
// backgroundColor:'red',
  cursor: "pointer",
};

const textStyleElippesWithTowLines = {
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
};

const SaveButton = () => (
  <div style={bottonWithBorderStyle}>
    <img
      src={Redhearticon}
      alt=""
      style={{ width: "12px", height: "12px" }}
      onClick={handleOpenNavMenu}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">Save</h1>
  </div>
);



const ShareButton = () => (
  <div style={bottonWithBorderStyle}>
    <img
      src={shareIcon}
      alt=""
      style={{ width: "12px", height: "12px" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">Share</h1>
  </div>
);

const ViewButton = (id:any) => {

  // const viewHandler = (j:any) => {
  //   console.log("viewHandler jjjj:",j)
  //   window.location.href =  `${appUrl}/offer/${j}`;
  // //  handleCloseNavMenu();
  // };
    return(
    <div style={bottonWithBorderStyle} >
    <img
      src={Eyegrey}
      alt=""
      style={{ width: "15px", height: "15px" }}
    />
    <EmptyBox size={2} />
    <h1 className="baloo2-normal-manatee-12px">View</h1>
  </div>
  )}
  const handleOpenNavMenu = () => {
    console.log("handleOpenNavMenu pressed")
  
  };

const gotobotton = {
  border: "1px solid var(--manatee)",
  borderRadius: "3px",
  paddingBlock: "1px",
  paddingInline: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop:'11px',
  marginBottom:'11px'
};

const bottonWithBorderStyle = {
  border: "1px solid var(--manatee)",
  borderRadius: "16px",
  paddingBlock: "1px",
  paddingInline: "12px",
  display: "flex",
  alignItems: "center",
};

const selectedSavebottonWithBorderStyle = {
  border: "1px solid var(--yellow)",
  background:'var(--yellow)',
  borderRadius: "16px",
  paddingBlock: "1px",
  paddingInline: "12px",
  display: "flex",
  alignItems: "center",
};

const titleStyle = {
  left: "20px",
  top: "10px",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
 // backgroundColor:'red'
};

const imageStyle = {
  width: "46px",
  height: "46px",
};

const oneLineText = {
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: "vertical",
};

const mapStateToProps = (state: any, props: any) => ({
 // offer: state.cartReducer.currentBrowsingProduct,
  merchant: state.merchantReducer.merchantDetails,
  ...props,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDetails: (merchantId: any) =>getMerchantDetails(merchantId, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferGridItem);
