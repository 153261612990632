import React, { useEffect, useState } from "react";
import  EventEmitter  from "../../utils/EventEm";
import EmptyBox from "../../_shared/components/empty_box";
import MerchantsFilters from "./components/merchants_filters/merchants_filters";
import "./merchants.css";
import MerchantsList from "./components/merchants_list/merchants_list";
import MerchantsHeader from "./components/merchants_header/merchants_header";
import { getMerchants,getMerchantsCateg } from "../../redux/merchant/thunks/merchant_thunks";
import { getCategories,getHotelSubCategories } from "../../redux/merchant/thunks/categories-thunks";
import  TestCategories  from "./components/merchants_categories/categories_list";
import { connect } from "react-redux";
import store from "../../redux/store";
import PaginationComponent from "./pagination";

import { tokenHandler  } from "../../services/services";

function Merchants({ stores, getStores,getStoresCat,categories,getCategories1,getHotelSubCategories,hotelSubCategories}) {
  console.log("categories categories:",categories)
 // console.log("hotelSubCategories hotelSubCategories:", hotelSubCategories)
  const [showAsGrid, setShowAsGrid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [test, setTest] = useState(true);
  console.log("ALl Merchants......:",stores);
  console.log( 'isCategorySelected&&&&&&&&&&&&&&&&:', isCategorySelected)
  const [filterData,setFilterData]= useState([]);
  const [storesSearchText,setStoresSearchText]= useState([]);
  

  useEffect(() => {
    
    tokenHandler();
    setIsLoading(true)
    getHotelSubCategories();
     console.log("starting")
      const user = ()=> {
        if (test)
        {
        getCategories1( {"parentCategory":"FALSE"}); 
        getStores({});
        setTest(false)
        }
      }
      user();
      console.log("starting")
     
    const categoryIdEmitt = (eventData) => {
      setIsCategorySelected(true)
      setSelectCategory(eventData)
      console.log( 'categoryIdEmitt eventData:', eventData)
      if(eventData.idCategory === "65882f58c85b99b5eb8edce5")
      {
        getStoresCat({pageNumber:0,category:eventData.idCategory});
        console.log(stores);
      
      }
     else if(eventData.parentCategory === "FALSE")
    {
      getStoresCat({pageNumber:0,category:eventData.idCategory});
      getCategories1({"parentCategory":eventData.idCategory});
      console.log(stores);
    
    }
    else 
    {
      getStoresCat({pageNumber:0,category:eventData.idCategory});
      //getCategories1({"parentCategory":eventData.idCategory});
      console.log(stores);
    }
    setIsLoading(false)
    }
    const listner = () => {      
      EventEmitter.addListener('categoryIdEmitt',categoryIdEmitt)
    }
    listner();
    
    setIsLoading(false)
  }, [test]);
  const  handleSearchHandler =(i)=>{
    console.log("handleSearchHandler All stores:",i,storesSearchText.length)
    setStoresSearchText(i)
    if(isNaN(i)) {
      let finalStore = stores.data ? stores.data : stores;
      const newData = finalStore.filter((item) => {
        const itemData  = item.merchName ? item.merchName.toLowerCase() : ''.toLowerCase()
        const textData = i.toLowerCase().trim();
        return itemData.indexOf(textData) > -1 ;
      }) 
      setFilterData(newData)
      console.log("newData:",newData)
    } 
    else {
      setFilterData([])
    }
   }
  const backHandler = () => {
    console.log("backHandler pressed");
    setIsCategorySelected(false)
    setSelectCategory(false)
    getCategories1({ parentCategory: "FALSE" });
    getStores({});
  };


  const onPressPaginationHandler = (i) => {
    console.log("onPressPaginationHandler pressed",i,selectCategory);
   let a = { "pageNumber":i, "limit":20 }
   !isCategorySelected ? getStores(a): getStoresCat({pageNumber:i,category:selectCategory.idCategory});
  }
  return (
    <div style={{ //marginInline: "2rem",
    //background:'red'
     }}>
      <EmptyBox size={"2rem"} />
      <div style={{ display: "flex" }}>
       {/* <div className="hide-on-medium-screen">
          <MerchantsFilters />
        </div>*/}
       
        <div style={{ }}>
         <TestCategories categories= {categories} isGrid={showAsGrid} onClick={backHandler} handleSearch={(i)=>{handleSearchHandler(i) }} />
         {isLoading && <div style={{ display:'flex',height:'25px',justifyContent:'center',alignItems:'center' }}>
    <span>Loading......</span>
   </div>}
         <MerchantsHeader onShowAsGridClicked={setShowAsGrid} handleSearch={(i)=>{handleSearchHandler(i) }} />
         { typeof stores === "undefined" ? (
            <EmptyBox size={"50vh"} />
          ) : (
            <>
              <PaginationComponent
            isGrid={showAsGrid}
             stores={filterData.length == 0 && storesSearchText.length == 0 ? stores:filterData}
             onPress={(i)=>onPressPaginationHandler(i)}
             />
            {/* <div style={{width:window.innerWidth *.8}}>
            <MerchantsList stores={filterData.length == 0 && storesSearchText.length == 0 ? stores:filterData} isGrid={showAsGrid} />
            </div> */}
            </>

          )}
   
        </div>
      </div>
      
    </div>
  );
  
}

const mapStateToProps = (state) => ({
  stores: state.merchantReducer.merchants,
  categories: state.merchantReducer.categories,
  hotelSubCategories: state.merchantReducer.hotelSubCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getStores: (body) => getMerchants(body,dispatch),
  getStoresCat: (categoryId) => getMerchantsCateg(categoryId,dispatch),
  getCategories1: (body) => getCategories(body,dispatch),
  getHotelSubCategories: (body) => getHotelSubCategories(body,dispatch),
  
  
  
});

export default connect(mapStateToProps,mapDispatchToProps)(Merchants);
