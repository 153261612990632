import instance from "../instance";
import {instance2} from "../instance";
import { addFavourites } from "./thunks/wish_thunks";

export const merchantApi = {
  getCategories: (body) => instance2.post('/merchants_category', body),
  getPremiumMerchant: (body) => instance.post('/res.partner/search/premium', body),
  getMerchant: (body) => instance2.post('/merchants', body),
  getMerchantCateg: (body) => instance2.post('/merchants', body),
  getAllMerchant: (body) => instance.post('/user/merchant/lists', body),
  getMerchantProducts: (body) => instance2.post('/get_products', body),
  getMerchantOffers: (body) => instance2.post('/get_offers', body),
  getMerchantDetails: (body) => instance2.post('/merchants', body),
  getOffers: (body) => instance2.post('/get_offers', body),
  getCardDetails: (body) => instance2.get('/get_user', body),
  addFavourites: (body) => instance2.post('/add_favorite', body),
  removeFavourites: (body) => instance2.post('/unfavorite', body),
  getFavorite: (body) => instance2.get('/get_favorite', body),
  getProducts: (body) => instance.post('/product.template/search', body),
  getFavoriteOffers: (body) => instance.post('/get/favourite/products', body),
  getOrganizations: (body) => instance.post('/user/org/lists', body),
  getOrganizationDetails: (body) => instance.post('/user/org/details', body),
  bookNow: (body) => instance.post('/merchant/hotel/enquiry', body),
  deleteAccount: (body) => instance.post('/user/archive', body),
  getAdvert: (body) => instance.post('/advertisement/banner', body),
  trackBanner: (body) => instance.post('/advertisement_tracking', body),
  getSocialMedia: (body) => instance.post('/user/social/v2', body),
  save: (body) => instance2.post('/add_favourite', body),
  track: (body) => instance.post('/merchant/track/v2', body),
  sendEmail: (body) => instance.post('/send/mail/v2', body),
  redeem: (body) => instance.post('/merchant/redeem/v2', body),
  changePassword: (body) => instance.post('/user/pass/update', body)
}

