import { getToken } from "../../auth/auth-reducer";
import { setCategories,setHotelSubCategories } from "../merchant-actions";
import { merchantApi } from "../merchant-api";

export const getCategories = async (body: any, dispatch: any) => {
  console.log("categories appi called");
  const res = await merchantApi.getCategories(body);
  console.log("getCategories appi res:", res);
  dispatch(setCategories(res.data));
};

export const getHotelSubCategories = async (body: any, dispatch: any) => {
  console.log("getHotelSubCategories appi called");
  const res = await merchantApi.getCategories({
    parentCategory: "65882f58c85b99b5eb8edcef",
  });
  console.log("getHotelSubCategories appi res:", res);
   dispatch(setHotelSubCategories(res.data))
};
