import zIndex from "@mui/material/styles/zIndex";
import React from "react";

export default function PopularCategoryGridItem({ category, className }: any) {

  return (
    <div className={className}>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <img
          src={category.categoryImg3}
          alt=""
          style={{ objectFit: "fill", width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            top: "40%",
            margin: "auto auto",
            textAlign: "center",
          }}
        >
          <span className="baloo2-normal-white-16px">{category.categoryName}</span>
        </div>
      </div>
    </div>
  );
}
