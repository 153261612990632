import { connect } from "react-redux";
import { Navigate } from "react-router";
import LoginForm from "./components/login_form";

function Login({ user }: any) {
  console.log("********Login*******:",user)
  if (user) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <LoginForm />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Login);
