import React, {useEffect,useState} from 'react';
import HomeAboutUs from "./components/about_us/about_us";
import HomeSliderCarousel from "./components/slider_carousel/home_slider_carousel";
import Categories from "./components/categories/categories";
import GetRewards from "./components/get_rewards/get_rewards";
import PopularCategories from "./components/popular_categories/popular_categories";
import PremiumPartners from "./components/premium_partners/premium_partners";
import Statistics from "./components/statistics/statistics";
import Merchants from "./components/merchants/merchants";
import Subscribe from "./components/subscribe/subscribe";
import WeekOffers from "./components/week_offers/week_offers";
import EmptyBox from "../../_shared/components/empty_box";
import Join from "./components/join/join";
import axios from 'axios'; // Import axios

export default function Home() {

  const [test, setTest] = useState("");


  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log("api/go/auth/pass fetchData starts:");
  //     try {
  //       const url = 'http://localhost:9000/api/go/auth/pass';
  //       const requestBody = {
  //         ji: "jj",
  //         walletTitle: "Golalitaf77776"
  //       };
  
  //       console.log("api/go/auth/pass fetchData requestBody url:",url,requestBody);
  //       const response = await axios.post(url, requestBody, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           // Add any other required headers here
  //         }
  //       });
  
  //       console.log("api/go/auth/pass response:", response);
  //       if (response.status === 200) { // Check status using response.status
  //         console.log("api/go/auth/pass res:", response.data); // Access response data directly
  //       } else {
  //         throw new Error('Failed to fetch data');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  
  useEffect(() => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    
    // Get the value of the 'token' parameter
    const token = queryParams.get('token');
    
    // Do something with the token, such as storing it in state or local storage
    console.log('Tokenaaaaaaaaaaaaaa app,tsx:', token);
    if (token) {
      localStorage.setItem("token", token);
    }
    // You may want to perform additional actions here, such as redirecting the user
    
  }, []); // Make sure to provide an empty dependency array to useEffect to run this effect only once

  // useEffect(() => {
  //   const messageListener = window.addEventListener('message', (nativeEvent) => {
  //     console.log("messagemessagemessage home.tss",nativeEvent);
  //    // setTest(nativeEvent?.data)
  //   });
  //   return messageListener;
  // }, []);
  function receiveDataFromNative(data:any) {
    // Do something with the received data
    console.log('Received data from React Native:', data);
  }
  
  return (
    <div>
      <HomeSliderCarousel />
      {/* <PremiumPartners /> */}
      <GetRewards />
      {/* <WeekOffers /> */}
      <HomeAboutUs />
      <PopularCategories />
      <EmptyBox size="100px" />
      <Categories />
      {/* <Merchants /> */}
      {/* <Subscribe /> */}
      <Statistics />
      {/* <Join /> */}
    </div>
  );
}
