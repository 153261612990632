import React, { useEffect,useMemo, useState } from "react";
import "./all_offers.css";
import { connect } from "react-redux";
import EmptyBox from "../../_shared/components/empty_box";
import AllOffersFilters from "./components/all_offers_filters/all_offers_filters";
import AllOffersHeader from "./components/all_offers_header/all_offers_header";
import AllOffersList from "./components/all_offers_list/all_offers_list";
import { getOffers } from "../../redux/merchant/thunks/offers_thunks";
import PaginationComponent from "./pagination";
import { getUserData } from "../../redux/auth/thunks/auth_thunks";
import { getCardDetails } from "../../redux/merchant/thunks/card_thunks";


import { API_BASE_URL } from "../../redux/instance"; 
import { appUrl } from "../../_shared/constants/app_urls";

import axios from 'axios';

import { tokenHandler  } from "../../services/services";

function AllOffers({ offers, getOffers ,card, getCardDetails}: any) {
  console.log("ALl offersss cards......:",card);
  console.log("ALl offersss......:",offers);
  const [offersData,setOffersData]= useState(offers && offers.data && offers.data.data && offers.data.data);
  const [filterData,setFilterData]= useState([]);
  const [offerSearchText,setOfferSearchText]= useState([]);
  
  const [showAsGrid, setShowAsGrid] = useState(true);
//   const tokenHandler =()=>{

// const token: string | null = localStorage.getItem("token");
//     axios.get(API_BASE_URL + "/get_user", {
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*',
//         'x-access-token': token,
//       },
//     })
//     .then(response => {
//       // Handle successful response
//       console.log('Response:', response.data);
//       if(response.data._id){
//       }else{
        
//   window.location.href =  `${appUrl}/invalid`;
//       }
//     })
//     .catch(error => {
//       // Handle error
//       console.log('Error res:', error.response.data.message);
//       window.location.href =  `${appUrl}/invalid`;
//     });
//   }
  useEffect(() => {
    
    //tokenHandler();
    tokenHandler();
    getCardDetails().then((res:any) => {
      console.log("AllOffers getUserData res:",res)
      
      // 
    });
    getOffers();
    console.log('worked:',offers)
  }, []);

  useMemo(() => {
  //  console.log('offerSearchTextofferSearchText',offerSearchText)
      }, [offerSearchText])

 const  handleSearchHandler =(i:any)=>{
  console.log("handleSearchHandler All Offers:",i,offerSearchText.length)
  setOfferSearchText(i)
  if(isNaN(i)) {
    const newData = offersData.filter((item:any) => {
      const itemData  = item.offerName ? item.offerName.toLowerCase() : ''.toLowerCase()
      const textData = i.toLowerCase().trim();
      return itemData.indexOf(textData) > -1 ;
    }) 
    setFilterData(newData)
    console.log("newData:",newData)
  } 
  else {
    setFilterData([])
  }
 }
 const onPressPaginationHandler = (i:any) => {
  console.log("onPressPaginationHandler pressed",i);
 let a = { "pageNumber":i, "limit":20 }
 getOffers(a);
}
  return (
    <div style={{ //marginInline: "2rem" 

    }}>
      <EmptyBox size={"2rem"} />
      <div style={{ display: "flex" }}>
        {/* <div className="hide-on-medium-screen">
          <AllOffersFilters />
        </div> */}
        <div style={{ flexGrow: 1 }}>
          <AllOffersHeader onShowAsGridClicked={setShowAsGrid} handleSearch={(i:any)=>{handleSearchHandler(i) }} />
          { ! offers ? (
            <EmptyBox size={"50vh"} />
          ) : (
            <PaginationComponent
            isGrid={showAsGrid}
             stores={filterData.length == 0 && offerSearchText.length == 0 ? offers:filterData}
             onPress={(i:any)=>onPressPaginationHandler(i)}
             />
            // <AllOffersList offers={ filterData.length == 0 && offerSearchText.length == 0 ? offersData:filterData} isGrid={showAsGrid} />
         
         )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  offers: state.merchantReducer.offers,
  card: state.merchantReducer.card
});

const mapDispatchToProps = (dispatch: any) => ({

  getCardDetails: () => getCardDetails(dispatch),
  getOffers: (i:any) => getOffers(i,dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllOffers);

