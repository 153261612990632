import {
  SET_IS_LOGIN_ERROR,
  SET_PUBLIC_ORGANIZATIONS,
  SET_TOKEN,
  SET_USER,
  SET_USER_ID,
  SET_WORK_STATUS
} from "./auth-types";

export const setUser = (user) => ({type: SET_USER, user})
export const setIsLoginError = (isLoginError) => ({type: SET_IS_LOGIN_ERROR, isLoginError})
export const setToken = (token) => ({type: SET_TOKEN, token})
export const setUserId = (userId) => ({type: SET_USER_ID, userId})
export const setWorkStatus = (workStatus) => ({type: SET_WORK_STATUS, workStatus})
export const setPublicOrganizations = (publicOrganizations) => ({type: SET_PUBLIC_ORGANIZATIONS, publicOrganizations})