import React from 'react';

function InvalidPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h3>Provided details are invalid....!</h3>
      <h3> Kindly contact Aldar exchange for more details.</h3>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <p>The page you are trying to access is invalid.</p>
      </div>
    </div>
  );
}

export default InvalidPage;
