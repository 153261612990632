import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getHomePopularCategories } from "../../../../redux/home/thunks/categories_thunks";
import {
  ClothingImage,
  HealthImage,
  RemoteImage,
  RestaurantImage,
  SportImage,
} from "../../../../_assets/images";
import EmptyBox from "../../../../_shared/components/empty_box";
import PopularCategoryGrid from "./widgets/popular_category_grid";
import PopularCategoryTitle from "./widgets/popular_category_title";

function PopularCategories({ popularCategories, getPopularCategories }: any) {
  useEffect(() => {
    getPopularCategories();
  }, []);
  return (
    <div>
      <EmptyBox size="100px" />
      <PopularCategoryTitle />
      <EmptyBox size="40px" />
      <PopularCategoryGrid list={popularCategories} />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  popularCategories: state.homeReducer.popularCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  getPopularCategories: () => getHomePopularCategories(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopularCategories);
