// src/BookingModal.js
import React from 'react';
import './BookingModal.css';

const BookingModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [formData, setFormData] = React.useState({
    name: '',
    mobileNumber: '',
    email: '',
    country: '',
    city: '',
    Check_inDate: '',
    Check_outDate: '',
    roomType: 'Standard Double Room',
    price: 0,
    numberOfAdults: 0,
    numberOfChildren: 0,
    note: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    console.log("formDataformData:",formData)
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onRequestClose}>X</button>
        <h2>Book Now</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Full name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Mobile number:
            <input type="tel" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Country:
            <input type="text" name="country" value={formData.country} onChange={handleChange} required />
          </label>
          {/* <label>
            City:
            <input type="text" name="city" value={formData.city} onChange={handleChange} required />
          </label> */}
          <label>
          Check-in:
            <input type="date" name="Check_inDate" value={formData.Check_inDate} onChange={handleChange} required />
          </label>
          <label>
          Check-out:
            <input type="date" name="Check_outDate" value={formData.Check_outDate} onChange={handleChange} required />
          </label>
          {/* <label>
            Room Type:
            <input type="text" name="roomType" value={formData.roomType} readOnly />
          </label> */}
          {/* <label>
            Price:
            <input type="number" name="price" value={formData.price} onChange={handleChange} required />
          </label> */}
          <label>
            Number of adults:
            <input type="number" name="numberOfAdults" value={formData.numberOfAdults} onChange={handleChange} required min="0" />
          </label>
          <label>
            Number of children:
            <input type="number" name="numberOfChildren" value={formData.numberOfChildren} onChange={handleChange} required min="0" />
          </label>
          <label>
            Note:
            <textarea name="note" value={formData.note} 
            rows="6"  
            placeholder="Enter any special requests (e.g., room type preference, specific price range, additional services)..."
            onChange={handleChange}></textarea>
          </label>
          <button type="submit" style={{ backgroundColor: '#1cc0a0', color: '#fff' }} >Send</button>
        </form>
      </div>
    </div>
  );
};

export default BookingModal;
